Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.getcategoryApiEndPoint = "bx_block_categories/categories"
exports.getUserAPIEndpoint = "bx_block_profile/profiles"
exports.jsonContentType = "application/json"
exports.getMethod = "GET"
exports.postMethod = "POST"
exports.createBookingRequestAPIEndpoint = "bx_block_request_management/requests"
// Customizable Area End
