import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import dayjs from "dayjs";

// Customizable Area Start

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  route:any;
}

interface ServiceRequest {
  startDate: any;
  startTime: string;
  endTime: string;
  address: string;
  postalCode: string;
  serviceName: string;
  requestType: 1 | 2;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  frequency: string | null;
  accountId: number;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhoneNumber: string;
  phoneNumberValid : boolean;
  recipientPhoneNumberValid: boolean;
}


interface S {
  selectYesOrNo: string;
  checkIfReqIsConfirmed: string;
  userCellNumberForYesCase: string;
  isUserCellNumberValidForYesCase: boolean;

  recipientCellNumberForNoCase: string;
  isRecipientCellNumberValidForNoCase: boolean;

  yourCellNumberForNoCase: string;
  isYourCellNumberValidForNoCase: boolean;
  isCalendarOpen : boolean;
  zipCode : string;
  zipError : boolean;
  serviceRequest : ServiceRequest;
}

interface SS {
  id: any;
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {

  getUserDetailsAPICallId: string = "";
  createBookingRequestAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      selectYesOrNo: "Yes",
      checkIfReqIsConfirmed: "initial",
      userCellNumberForYesCase: "",
      isUserCellNumberValidForYesCase: true,

      recipientCellNumberForNoCase: "",
      isRecipientCellNumberValidForNoCase: true,

      yourCellNumberForNoCase: "",
      isYourCellNumberValidForNoCase: true,
      isCalendarOpen : false,
      zipCode : "",
      zipError : false,
      serviceRequest : {
        startDate: null,
        startTime: "",
        endTime: "",
        address: "",
        postalCode: "",
        serviceName: "",
        requestType: 1,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        frequency: null,
        accountId: 0,
        recipientFirstName: "",
        recipientLastName: "",
        recipientPhoneNumber: "",
        phoneNumberValid : true,
        recipientPhoneNumberValid: true
      }
    };

    this.handleUserCellNumberForYesCase = this.handleUserCellNumberForYesCase.bind(this);
    this.cellNumberHelperText1 = this.cellNumberHelperText1.bind(this);
    this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);

    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getUserDetailsAPICallId) {
      let userData = responseJson.data.attributes;
      this.setState((prevState) => ({
        serviceRequest: {
          ...prevState.serviceRequest,
          firstName : userData.first_name,
          lastName : userData.last_name,
          phoneNumber : userData.phone_number,
          address : userData.address
        }
      }));
    }

    else if (apiRequestCallId === this.createBookingRequestAPICallId) {
       this.setState({
      checkIfReqIsConfirmed: "success"
    });
      
    }


    
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getUserDetails();
    const savedServiceRequest = JSON.parse(localStorage.getItem('serviceRequest') || '{}');
    let data = JSON.parse(String(localStorage.getItem("login")))
    const {selectedService, zipCode, preferedDate} = savedServiceRequest;
    this.setState((prevState) => ({
      serviceRequest: {
        ...prevState.serviceRequest,
        postalCode : zipCode,
        startDate : dayjs(preferedDate),
        serviceName : selectedService,
        accountId : data.id
      }
    }));

  }

  isNumber(value: number) {
    return !isNaN(parseFloat(String(value))) && isFinite(value);
  }

  handleYesOrNo(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      selectYesOrNo: event.target.value
    });
  }

  handleConfirmRequest() {
    this.createBookingRequest()
  }

  handleUserCellNumberForYesCase(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    const checkLength = event.target.value.length === 10;
    this.setState({
      isUserCellNumberValidForYesCase: (isValid && checkLength),
      userCellNumberForYesCase: event.target.value,
    })
  }

  handleRecipientCellNumberForNoCase(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    const checkLength = event.target.value.length === 10;
    this.setState({
      isRecipientCellNumberValidForNoCase: (isValid && checkLength),
      recipientCellNumberForNoCase: event.target.value,
    })
  }

  handleYourCellNumberForNoCase(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    const checkLength = event.target.value.length === 10;
    this.setState({
      isYourCellNumberValidForNoCase: (isValid && checkLength),
      yourCellNumberForNoCase: event.target.value,
    })
  }

  cellNumberHelperText1() {
    if (!this.state.userCellNumberForYesCase || this.state.isUserCellNumberValidForYesCase) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  cellNumberHelperText2() {
    if (!this.state.recipientCellNumberForNoCase || this.state.isRecipientCellNumberValidForNoCase) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  cellNumberHelperText3() {
    if (!this.state.yourCellNumberForNoCase || this.state.isYourCellNumberValidForNoCase) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  goTermsConditions = () => {
    localStorage.setItem("termsAndPolicy", "2");
    this.handleNavigation("TermsConditions");
  }

  goToHomeScreenFromSuccess() {
    this.handleNavigation("AdvancedSearch");
  }

  goToHomeScreenFromFailure() {
    this.handleNavigation("AdvancedSearch");
  }

  handleNavigation = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

  handleZipCodeChange = (e : any) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    const zipRegex = /^[0-9]{5,6}$/;

    this.setState((prevState) => ({
      serviceRequest: {
        ...prevState.serviceRequest,
        postalCode: numericValue
      },
      zipError: !zipRegex.test(numericValue),
    }));
};

getUserDetails() {
  let loginData = localStorage.getItem("login") as string;
  let loginObject = JSON.parse(loginData)

  const PCheaders = {
    "Content-Type": configJSON.jsonContentType,
    token: loginObject?.token
  };

  const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getUserDetailsAPICallId = getAllData.messageId;

  getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getUserAPIEndpoint}`);

  getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(PCheaders));

  getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getMethod
  );
  runEngine.sendMessage(getAllData.id, getAllData);
}

createBookingRequest = async () => {
  let data = JSON.parse(String(localStorage.getItem("login")))
 
 
  const header = {
      "Content-Type": configJSON.jsonContentType,
      token: data?.token
  };
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createBookingRequestAPICallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBookingRequestAPIEndpoint
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  const dateObject = this.state.serviceRequest.startDate
  const formattedDate = dayjs(dateObject?.$d).format('YYYY-MM-DD');

  let body= {
    "start_date": formattedDate,
    "start_time": this.state.serviceRequest.startTime,
    "end_time": this.state.serviceRequest.endTime,
    "address": this.state.serviceRequest.address,
    "postal_code": this.state.serviceRequest.postalCode,
    "service_name": this.state.serviceRequest.serviceName,
    "request_type": this.state.selectYesOrNo === "Yes" ? 1:2,
    "first_name": this.state.serviceRequest.firstName,
    "last_name": this.state.serviceRequest.lastName,
    "phone_number": this.state.serviceRequest.phoneNumber,
    "frequency": null,
    "account_id": this.state.serviceRequest.accountId,
    "recipient_first_name": this.state.serviceRequest.recipientFirstName,
    "recipient_last_name": this.state.serviceRequest.recipientLastName,
    "recipient_phone_number": this.state.serviceRequest.recipientPhoneNumber
}

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  handleInputChange = (field: any, value: string | number | null |any): void => {

   if (field === 'phoneNumber' || field === 'recipientPhoneNumber') {
    // Only apply formatting if value is a string
    if (typeof value === 'string') {
      const { formattedValue, isValid } = this.formatPhoneNumber(value);
      
      this.setState((prevState) => ({
        serviceRequest: {
          ...prevState.serviceRequest,
          [field]: formattedValue,
          [`${field}Valid`]: isValid
        }
      }));
      return;
    }
  }
   
    this.setState((prevState) => ({
      serviceRequest: {
        ...prevState.serviceRequest,
        [field]: value
      }
    }));
  };

  handleStartTimeChange(newStartTime: string): void {
    this.setState(prevState => ({
      serviceRequest: {
        ...prevState.serviceRequest,
        startTime: newStartTime
      }
    }));
  }

  handleEndTimeChange(newEndTime: string): void {
    this.setState(prevState => ({
      serviceRequest: {
        ...prevState.serviceRequest,
        endTime: newEndTime
      }
    }));
  }

  formatPhoneNumber = (value: string): { formattedValue: string, isValid: boolean } => {
    // Remove non-numeric characters
    const cleanedValue = value.replace(/\D/g, "");
    let formattedValue = cleanedValue;
    
    // Format the phone number
    if (cleanedValue.length > 3) {
      formattedValue = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}`;
    }
    if (cleanedValue.length > 6) {
      formattedValue += `-${cleanedValue.slice(6, 10)}`;
    }
    
    // Validate: US phone numbers should be 10 digits
    const isValid = cleanedValue.length === 10;
    
    return { formattedValue, isValid };
  };

  isCreateBookingDisabled = () => {
    const { serviceRequest } = this.state;

    const commonFieldsValid = 
      serviceRequest.firstName !== "" &&
      serviceRequest.lastName !== "" &&
      serviceRequest.phoneNumber !== "" &&
      serviceRequest.phoneNumberValid &&
      serviceRequest.address !== "" &&
      serviceRequest.postalCode !== "" &&
      serviceRequest.startDate !== null &&
      serviceRequest.startTime !== "" &&
      serviceRequest.endTime !== "";
    
    if (this.state.selectYesOrNo === "Yes") {
      return !commonFieldsValid;
    } else {
      const recipientFieldsValid =
        serviceRequest.recipientFirstName !== "" &&
        serviceRequest.recipientLastName !== "" &&
        serviceRequest.recipientPhoneNumber !== "" &&
        serviceRequest.recipientPhoneNumberValid;
        
      return !(commonFieldsValid && recipientFieldsValid);
    }
  };

}

// Customizable Area End
