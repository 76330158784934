import React from 'react';
import { Box, Button, CardContent, Chip, Pagination, Typography } from '@mui/material';
import { styled } from '@mui/system';
import UserProfileController, { Props } from "./UserProfileController";
import HeadphonesIcon from '@mui/icons-material/Headphones';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RefreshIcon from '@mui/icons-material/Refresh';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { boy2, girlprofile, locationn, logo, profile_men, userIcon } from './assets';

const InfoBox = styled(Box)({
    width: '186px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});

const users = [
    {
        number: '1.',
        name: "Jack Tohas",
        location: "New York",
        state: "New York",
        zip: "10956",
        rate: "120/h",
        image: <img src={profile_men.default} alt="" className='imgcss' />,
        services: ["Tech Help", "Indoor Help", "Organizing", "Friendly Visits"],
        recommendations: ["Reliable", "Trustworthy", "Works Hard", "Problem Solver"],
    },
    {
        number: '2.',
        name: "Ganna Ytun",
        location: "New York",
        state: "New York",
        zip: "10956",
        rate: "120/h",
        image: <img src={girlprofile.default} alt="" className='imgcss' />,
        services: ["Tech Help", "Indoor Help", "Organizing", "Friendly Visits"],
        recommendations: ["Reliable", "Trustworthy", "Works Hard", "Problem Solver"],
    },
    {
        number: '3.',
        name: "Kan Janes",
        location: "New York",
        state: "New York",
        zip: "10956",
        rate: "120/h",
        image: <img src={boy2.default} alt="" className='imgcss' />,
        services: ["Tech Help", "Indoor Help", "Organizing", "Friendly Visits"],
        recommendations: ["Reliable", "Trustworthy", "Works Hard", "Problem Solver"],
    }
];

class BookingRequest extends UserProfileController {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <MainContainer>
                <Box className="navBoxContainer">
                    <Box className="navBox">
                        <img src={logo.default} />
                        <img src={userIcon.default} className="userIcon" />
                        <Box className="nameBox">
                            <Typography className="nameText">{ }</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Container Box */}
                <Box className="box1">
                    <Box className="box2" >
                        <InfoBox className='box3'>
                            <HeadphonesIcon /> Tech Help <ArrowDropDownIcon className='dropdownicon' />
                        </InfoBox>
                        <InfoBox className='box3'>
                            <PlaceIcon /> New York, New… <ArrowDropDownIcon className='dropdownicon' />
                        </InfoBox>
                        <InfoBox className='box3'>
                            <CalendarTodayIcon />  12 May - 13 May <ArrowDropDownIcon className='dropdownicon' />
                        </InfoBox>
                        <InfoBox className='box3'>
                            <RefreshIcon />  Once <ArrowDropDownIcon className='dropdownicon' />
                        </InfoBox>
                        <InfoBox className='box3'>
                            <QueryBuilderIcon />  11:00 am <ArrowDropDownIcon className='dropdownicon' />
                        </InfoBox>
                        <InfoBox className='box3'>
                            <QueryBuilderIcon /> 02:00 pm <ArrowDropDownIcon className='dropdownicon' />
                        </InfoBox>
                    </Box>
                    <Box className="button">
                        <Button variant="outlined" sx={{ borderRadius: '10px', padding: "12px 24px" ,width:'136px',height:'43px' ,fontWeight: 600, fontSize: "16px", fontFamily: 'Manrope', color:'#5E70DD'}} 
                        >Reset</Button>
                        <Button variant="contained" sx={{ borderRadius: '10px', padding: "12px 24px", backgroundColor: "#5E70DD",width:'136px',height:'43px',fontWeight: 600, fontSize: "16px"
                            , fontFamily: 'Manrope'}} >Apply</Button>
                    </Box>
                </Box>
                <Box >
                    {users.map((user, index) => (
                        <Box key={index} className="card" sx={{ my: 2, p: 2 }}>
                            <Box style={{
                                display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500, fontSize: "24px", paddingLeft: "2px", fontFamily: 'DM Sans'
                            }}>{user.number}</Box>
                            <Box
                                style={{ height: "319px", width: "284px", position: "relative", objectFit: "cover", borderRadius: '4px' }}>
                                {user.image}
                            </Box>
                            <CardContent className='CardContent'>
                                <Typography variant="h6" className='userName'>{user.name}</Typography>
                                <Typography variant="body2" className='body2'>
                                    <Box style={{
                                        width: "90px", height: "24px", display: "flex", gap: "5px", fontWeight: 400, fontSize: "16px", fontFamily: 'DM Sans',
                                        color: "#141414B2", justifyContent: "center", alignItems: "center"
                                    }}> <img src={locationn.default} style={{ width: "16px", height: '16px' }} />
                                        {user.location}</Box>
                                    <li><strong style={{ fontWeight: 600, fontSize: "16px", fontFamily: 'Manrope' }}>State:</strong>
                                        <strong style={{ fontWeight: 400, fontSize: "16px", fontFamily: 'DM Sans', color: "#141414B2" }}>{user.state}</strong>
                                    </li>
                                    <li style={{ gap: '10px', display: 'flex' }} >
                                        <Box>
                                            <strong style={{ fontWeight: 600, fontSize: "16px", fontFamily: 'Manrope' }}>ZIP Code:</strong>
                                            <strong style={{ fontWeight: 400, fontSize: "16px", fontFamily: 'DM Sans', color: "#141414B2" }}>{user.zip}</strong>
                                        </Box>
                                        <strong style={{ fontWeight: 400, fontSize: "16px", fontFamily: 'DM Sans', color: "#141414B2" }}>${user.rate}</strong>
                                    </li>
                                </Typography>
                                <Typography variant='h6'
                                    style={{
                                        color: "#141414B2", fontSize: "16px", fontStyle: "italic", fontWeight: 500, fontFamily: 'DM Sans'
                                    }}>"Empowering tomorrow's leaders today"</Typography>
                                <Box>
                                    <Typography style={{ fontWeight: 700, fontSize: "16px", fontFamily: 'Manrope' }}>How I Can Help</Typography>
                                    <Typography variant='h6' style={{ color: "#141414B2", fontSize: "16px", fontFamily: "DM Sans", fontWeight: 400 }} >"Help with grocery shopping, picking up prescriptions, or running other errands. This can be a big help for seniors who have trouble getting around."</Typography>
                                </Box>
                                <Typography style={{ fontWeight: 700, fontSize: "16px", color: "#141414", fontFamily: 'Manrope' }}>Services I Can Provide</Typography>
                                <Box style={{ display: "flex" }}>
                                    {user.services.map((service, i) => (
                                        <Chip key={i} label={service} sx={{ m: 0.5, backgroundColor: "white", fontWeight: 700, fontSize: "12.21px", color: "#393939", fontFamily: 'Manrope' }} />
                                    ))}
                                </Box>
                                <strong style={{ fontWeight: 700, fontSize: "16px", color: "#141414", fontFamily: 'Manrope' }}>Recommendations</strong>
                                <Box style={{ display: "flex" }}>
                                    {user.recommendations.length ? (
                                        user.recommendations.map((rec, i) => <Chip key={i} label={rec} sx={{
                                            m: 0.5, backgroundColor: "white",
                                            fontWeight: 700, fontSize: "12.21px", color: "#393939", fontFamily: 'Manrope'
                                        }} />)
                                    ) : (
                                        <Typography variant="body2">No recommendations yet</Typography>
                                    )}
                                </Box>
                            </CardContent>
                        </Box>
                    ))}
                </Box>
                <Box style={{
                    marginTop: "50px",
                    marginBottom: '50px'
                }}>
                    <Pagination count={33} shape="rounded" color='primary'
                     sx={{
                        "& .Mui-selected": {
                          backgroundColor: "#5E70DD !important", 
                          color: "white !important",
                        },
                        }} />
                </Box>
                <footer style={{ backgroundColor: "#5E70DD", width: "1440px", height: "88px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box style={{ display: 'flex', width: "1280px", height: "40px", justifyContent: "space-between", alignItems: "center" }}>
                        <Box style={{ fontWeight: 400, fontSize: "14px", color: "#FFFFFF", fontFamily: 'Roboto' }}>CompanyName @ 202X. All rights reserved.</Box>
                        <Box style={{ fontWeight: 400, fontSize: "16px", color: "#FFFFFF", fontFamily: 'Manrope' }}>Terms & Conditions</Box>
                    </Box>
                </footer>
            </MainContainer>
        );
    }
}

export default BookingRequest;

const MainContainer = styled(Box)({
    justifyContent: "center",
    display: 'flex',
    width: "100%",
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    gap: "20px",
    '@media (max-width: 600px)': {
        padding: '30px',
    },
    "& .navBoxContainer": {
        borderBottom: "1px solid #C1C7CD",
        height: "74px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        },
        "@media (max-width: 768px)": {
            justifyContent: "space-around",
            padding: "0px",
        },
    },
    "& .navBox": {
        alignItems: "center",
        width: "1200px",
        display: "flex",
        justifyContent: "space-between",
        height: "74px",
        "@media (max-width: 768px)": {
            justifyContent: "space-around",
            padding: "0px"
        },
    },
    "& .nameBox": {
        justifyContent: "center",
        display: "flex",
        maxWidth: "213px",
        alignItems: "center",
        borderLeft: "1px solid #D6DDEB",
        height: "48px",
        width: "100%",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .userIcon": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },
    '& .box1': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        backgroundColor: '#F1F5F9',
        width: "1272px",
        height: "163px",
        top: "161px",
        left: "84px",
        borderRadius: "13px",
        padding: "17px",
        marginTop: "50px"
    },
    '& .box2': {
        display: 'flex',
        gap: '19px',
        width: '1,211px',
        hight: "50px",
        top: "190px",
        left: "111px",
        borderRadius: '13px',
        boxShadow: 3,
        padding: 16
    },
    '& .box3': {
        width: "186px",
        height: "50px",
        top: "10.39px",
        backgroundColor: 'white',
        padding: "5px",
        fontFamily: 'Manrope',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '26px',
        letterSpacing: ' -0.1px',
        dislplay: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    },
    '& .button': {
        display: 'flex',
        gap: '16px',
        justifyContent: 'flex-end',
        paddingRight: "12px",
        height: "43px"
    },
    '& .card': {
        display: "flex",
        width: "1272px",
        backgroundColor: "#F1F5F9",
        height: "477px",
        top: "359px",
        left: "84px",
        gap: "70px",
        borderRadius: "8px",
        borderWidth: "1px",
    },
    '& .CardContent': {
        display: "flex",
        flexDirection: "column",
        width: "504.5px",
        backgroundColor: "#F1F5F9",
        height: "389px",
        gap: "16px",
        borderRadius: "8px",
        borderWidth: "1px",
    },
    '& .userName': {
        width: "auto",
        height: "36px",
        color: "#141414",
        fontWeight: 500,
        fontSize: "24px",
        fontFamily: 'DM Sans',
        lineHeight: '150%',
    },
    '& .body2': {
        display: "flex",
        width: "493px",
        height: "24px",
        gap: "16px"
    },
    '& .imgcss': {
        height: "319px",
        width: "284px",
        position: "relative",
        top: "4rem",
        objectFit: "cover",
        borderRadius: '4px'
    },
    '& .dropdownicon': {
        width: '18px',
        height: '18px',
        color: '#161C2D'
    }
}); 