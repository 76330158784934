import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button, CardContent,
    IconButton,
    Popover
} from "@mui/material";
import { clock, close, locationn, logo, msgicon, profile_image, profile_men, Receipt, search, shedule, userIcon } from "./assets"
import CloseIcon from '@mui/icons-material/Close';
import UserProfileController, { Props } from "./UserProfileController";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
// Customizable Area End

class UserProfileDetails extends UserProfileController {
    //   static SocialMediaAccount: SocialMediaAccount;

    constructor(props: Props) {
        super(props);

    }

    // Customizable Area Start
    card() {
        return (
            <CardContent
                className="CardContents" >
                <Typography
                    variant="body1"
                    className="textBox">
                    <strong
                        className="RequestText">
                        Booking Request ID:</strong>
                    <strong className="spanText">008928340948 </strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Service:</strong>
                    <strong className="spanText">Tech Help</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Address:</strong>
                    <strong className="spanTexts"> 13th Street, 47 W 13th St, New York, NY 10011</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Start Date:</strong>
                    <strong className="spanText">14 Aug</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Frequency:</strong>
                    <strong className="spanText"> Once</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Start Time:</strong>
                    <strong className="spanText"> 11:00 AM</strong>
                </Typography>
                <Typography variant="body1"
                    className="textBox">
                    <strong className="RequestText">End Time:</strong>
                    <strong className="spanText"> 12:00 PM</strong>
                </Typography>
            </CardContent>
        )
    }
    renderTabOne() {
        return (
            <Box
                style={{
                    padding: "28px 0px"
                }}>
                <Box
                    style={cardStyle}>
                    {this.card()}
                    <Box>
                        <Button className="paidBtn">Search for Students</Button>
                    </Box>
                </Box>

                <Box style={cardStyle}>
                    {this.card()}
                    <Box>
                        <Button className="paidBtn">Search for Students</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    renderTabTwo() {
        return (
            <Box>
                <Box style={tab2cardStyle}>
                    {this.card()}
                    <Box style={{ display: "flex", gap: "2rem" }}>
                        <Box className="btnBox">
                            <Box
                                onClick={this.Rescheduleopen}
                                className="imgBox">
                                <img src={shedule.default} alt="" />
                            </Box>
                            <Typography className="textbtn">Reschedule</Typography>
                        </Box>
                        <Container open={this.state.open}>
                            <Box style={{ height: "738px", width: "976px" }} className="box1">
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box onClick={this.handleClose}>
                                        <CloseIcon style={{ width: '32px', height: '32px', color: "#334155" }} />
                                    </Box>
                                </Box>
                                <Box className="card" style={{ paddingLeft: "3rem", margin: "30px" }}>
                                    <img style={{ height: "176px", width: "148px", objectFit: "cover" }}
                                        src={profile_men.default}
                                    />
                                    <Box className='CardContent'>
                                        <Typography variant="h6" className='userName' style={{ width: "auto" }}>Jack Tohas</Typography>
                                        <Typography className='body2'>
                                            <Box style={{ width: "93px", height: "24px", display: "flex", gap: "2px" }}> <img src={locationn.default} />
                                                <Typography className="texts"> New York</Typography></Box>
                                            <strong style={{ fontSize: "16px", color: "#141414", fontWeight: 600, fontFamily: "Manrope" }}>State:</strong><Typography className="texts"> New York</Typography>
                                            <strong style={{ fontSize: "16px", color: "#141414", fontWeight: 600, fontFamily: "Manrope" }}>ZIP Code:</strong><Typography className="texts">10956</Typography>
                                        </Typography>
                                        <Typography className="texts">$ 120/h</Typography>
                                        <Typography style={{ fontWeight: 700, fontSize: "16px", fontFamily: "Manrope", }}>Services</Typography>
                                        <select style={{ width: "208px", height: "30.47px", borderRadius: "10px", padding: "5px", border: "none", backgroundColor: "white" }}>
                                            <option style={{ fontSize: "12.21px", color: "#393939", fontWeight: 700, fontFamily: "Manrope" }}>Intergenerational Activities</option>
                                        </select>
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", gap: "6rem", justifyContent: "center", alignItems: "center" }}>
                                    <Box>
                                        <Box style={{ width: "374px", height: "383px", backgroundColor: "white", borderRadius: "9px", border: '1px solid #CBD5E1' }}>
                                            <Typography style={{ fontWeight: 700, fontSize: "20px", fontFamily: "Manrope", paddingTop: '20px', paddingLeft: '38px' }}>Confirm Start Date</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateCalendar />
                                            </LocalizationProvider>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
                                        <Box style={{
                                            width: "359px", height: "126px", display: "flex", flexDirection: "column", gap: "10px",
                                            boxSizing: "border-box", backgroundColor: "white", borderRadius: "9px", padding: "1rem", border: '1px solid #CBD5E1'
                                        }}>
                                            <Box style={{ fontSize: "20px", fontWeight: 600, fontFamily: "Manrope" }} >Start Time</Box>
                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                <QueryBuilderIcon
                                                    style={{
                                                        position: "absolute",
                                                        left: "10px",
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                        color: "#0F172A",
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                                <select
                                                    style={{
                                                        width: "239px",
                                                        height: "50px",
                                                        textAlign: "center",
                                                        fontSize: "16px",
                                                        borderRadius: "5px",
                                                        border: "1px solid #ccc",
                                                        padding: "5px",
                                                        backgroundColor: "#FFFFFF",
                                                        paddingLeft: "35px",
                                                    }}
                                                >
                                                    <option>11:00</option>
                                                </select>
                                            </div>
                                        </Box>
                                        <Box style={{ width: "359px", height: "126px", backgroundColor: "white", borderRadius: "9px" }}>
                                            <Box style={{
                                                width: "359px", height: "126px", display: "flex", flexDirection: "column", gap: "10px",
                                                boxSizing: "border-box", backgroundColor: "white", borderRadius: "9px", padding: "1rem", border: '1px solid #CBD5E1'
                                            }}>
                                                <Box style={{ fontSize: "20px", fontWeight: 600, fontFamily: "Manrope" }} >End Time</Box>
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <QueryBuilderIcon
                                                        style={{
                                                            position: "absolute",
                                                            left: "10px",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                            color: "#0F172A",
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                    <select
                                                        style={{
                                                            width: "239px",
                                                            height: "50px",
                                                            textAlign: "center",
                                                            fontSize: "16px",
                                                            borderRadius: "5px",
                                                            border: "1px solid #ccc",
                                                            padding: "5px",
                                                            backgroundColor: "#FFFFFF",
                                                            paddingLeft: "35px",
                                                        }}
                                                    >
                                                        <option>11:00</option>
                                                    </select>
                                                </div>
                                            </Box>
                                        </Box>
                                        <Button style={{ backgroundColor: "#5E70DD", fontWeight: 700, fontSize: "16px", fontFamily: "Roboto", color: '#FFFFFF' }}
                                            variant='contained'>Reschedule This Student</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                        <Box className="btnBox">
                            <Box className="imgBox">
                                <img src={close.default} alt="" />
                            </Box>
                            <Typography className="textbtn">Cancel</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
    renderTabThird() {
        return (
            <Box>
                {[...Array(3)].map((_, index) => (
                    <Box key={index} style={tab3cardStyle}>
                        {this.card()}
                        <Box style={{ display: "flex", gap: "5rem", paddingRight: "2rem" }}>
                            <Box className="btnBox"
                                onClick={this.handleOpen}>
                                <Box className="imgBox">
                                    <img src={Receipt.default} alt="" />
                                </Box>
                                <Typography className="textbtn">See Receipt</Typography>
                            </Box>
                            <ContainerTab open={this.state.open} onClose={this.handleClose}>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "828px", height: '32px', padding: '10px' }}>
                                    <Typography style={{
                                        fontFamily: "Manrope",
                                        fontSize: "24px",
                                        fontWeight: 700,
                                        lineHeight: "32px",
                                        color: '#0F172A',
                                        paddingTop: '38px'
                                    }}>
                                        Booking Receipt
                                    </Typography>
                                    <IconButton onClick={this.handleClose} style={{ padding: 0 }}>
                                        <CloseIcon style={{ width: '32px', height: '32px' }} />
                                    </IconButton>
                                </Box>
                                <Box style={{ padding: "20px", display: 'flex' }}>
                                    <Box style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingTop: "12px",
                                        paddingBottom: "12px",
                                        gap: "12px",
                                        borderTopLeftRadius: "8px",
                                        borderTopRightRadius: "8px",
                                        width: "289.92px",
                                        height: "95px",
                                        marginBottom: "50px",
                                    }}>
                                        <img src={profile_men.default} style={{ width: '75px', height: "71px", borderRadius: '4px' }} />
                                        <Box style={{
                                            gap: '10px', width: "175px",
                                            height: "67px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            flexDirection: "column"
                                        }}>
                                            <Typography style={{
                                                color: "#11141A",
                                                fontWeight: 400,
                                                fontFamily: "Manrope",
                                                lineHeight: "21px",
                                                fontSize: "10px",
                                            }}>Sun 16 July 2023 at 5:00pm</Typography>
                                            <Typography variant="subtitle1" style={{
                                                color: "#11141A",
                                                fontWeight: 700,
                                                fontFamily: "Manrope",
                                                lineHeight: "0px",
                                                fontSize: "17px",
                                            }}>
                                                Jack Tohas</Typography>
                                            <Typography
                                                style={{
                                                    color: "#11141A",
                                                    fontWeight: 400,
                                                    fontFamily: "Manrope",
                                                    lineHeight: "21px",
                                                    fontSize: "14px",
                                                    width: '180px'
                                                }}
                                            >
                                                Intergenerational Activities</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{
                                        width: '310px', height: '114px', gap: '14px', display: "flex",
                                        alignItems: "center", justifyContent: "space-between", flexDirection: "column", paddingTop: "17px"
                                    }}>
                                        <Typography style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: '310px'
                                        }}
                                        ><strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "18px",
                                            color: '#707070',
                                        }}
                                        >References Number:</strong> <strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "18px",
                                            color: '#121212',
                                        }}>000085752257</strong>  </Typography>
                                        <Typography style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: '310px'
                                        }}><strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "18px",
                                            color: '#707070',
                                        }}>Date</strong><strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "18px",
                                            color: '#121212',
                                        }}> Mar 22, 2023</strong></Typography>
                                        <Typography style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: '310px'
                                        }}>
                                            <strong style={{
                                                fontFamily: "Manrope",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "18px",
                                                color: '#707070',

                                            }}>Time</strong> <strong style={{
                                                fontFamily: "Manrope",
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "18px",
                                                color: '#121212',
                                            }}>07:80 AM</strong></Typography>
                                        <Typography style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: '310px'
                                        }}><strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "18px",
                                            color: '#707070',
                                        }}>Payment Method</strong> <strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "18px",
                                            color: '#121212',
                                        }}>Credit Card</strong></Typography>
                                        <Typography style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: '310px'
                                        }}><strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "18px",
                                            color: '#707070',
                                        }}>Amount</strong><strong style={{
                                            fontFamily: "Manrope",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "18px",
                                            color: '#121212',
                                        }}> $50,00</strong></Typography>
                                    </Box>
                                </Box>
                                <Box style={{
                                    width: "908px",
                                    height: "104px",
                                    top: "297px",
                                    borderTop: "1px solid black",
                                    borderBottomRightRadius: "32px",
                                    borderBottomLeftRadius: "8px",
                                    border: "1px solid #E2E8F0"
                                }}>
                                </Box>
                            </ContainerTab>
                            <Box className="btnBoxtab">
                                <Box className="imgBox1">
                                    <img src={search.default} alt="" />
                                </Box>
                                <Typography className="textbtn">Rebook this Service</Typography>
                            </Box>
                        </Box>

                    </Box>
                ))}
            </Box>
        )
    }
    renderTabForth() {
        return (
            <Box>
                <Box style={tab2cardStyle}>
                    {this.card()}
                    <Box style={{ display: "flex" }}>
                        <Box className="btnBoxtab">
                            <Box className="imgBox">
                                <img src={search.default} alt="" />
                            </Box>
                            <Typography className="textbtn">Rebook this Service</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        let { tabs } = this.state

        return (
            <MainContainer>
                <Box className="navBoxContainer">
                    <Box className="navBox">
                        <img src={logo.default} />
                        <img src={userIcon.default} className="userIcon" />
                        <Box className="nameBox">
                            <Typography className="nameText">{ }</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="ContentContainer">
                    <Box className="alignContainer">
                        <Box className="heading">
                            <Typography style={{
                                color: "#25324B",
                                fontWeight: 700,
                                fontFamily: "Manrope",
                                lineHeight: "120%",
                                fontSize: "24px",
                            }}>
                                Client Profile
                            </Typography>
                        </Box>
                        <Box className="maindetailsbox">
                            <Box className='secBox'>
                                <img src={profile_image.default} alt="" className="profileimg" />
                                <Box className="detailsbox">
                                    <Typography className="nameboxx">
                                        Jenny Wilson
                                    </Typography>
                                    <Box className='beatbox'>
                                        <Box className="textBox"> 
                                            <Typography className="RequestTexts">Plan:</Typography>
                                            <Typography className="spanText">Talk</Typography></Box>
                                        <Box className="textBox">
                                            <Typography className="RequestTexts"> Email:</Typography>
                                            <Typography className="spanText">your.name@email.com</Typography>
                                        </Box>
                                        <Box className="textBox">
                                            <Typography className="RequestTexts"> Cell Number:</Typography>
                                            <Typography className="spanText"> (201) 555-1111</Typography>
                                        </Box>
                                        <Box className="textBox">
                                            <Typography className="RequestTexts">Address: </Typography>
                                            <Typography className="spanText">  13th Street. 47 W 13th St, New York, NY 10011</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <Box style={{ display: 'flex', gap: "2rem" }}>
                                    <Button className="msgBtn">
                                        <img src={msgicon.default} />
                                    </Button>
                                    <Button className="paidBtnn"
                                        onClick={() => window.location.href = "/BookingRequest"}
                                    >Create new booking request</Button>
                                </Box>

                            </Box>
                        </Box>
                        <Box className="headingg">
                            <Typography style={{
                                fontFamily: "Manrope",
                                fontSize: "24px",
                                fontWeight: 700,
                                lineHeight: "120%",
                                color: "#25324B"
                            }}>
                                Bookings
                            </Typography>
                        </Box>

                        <Box className="tabsBox">
                            {tabs.map((item, index) =>
                                <Box>
                                    <Typography className="tabsText" style={{ color: `${(index == this.state.currentTab) ? "#25324B" : "#7C8493"}` }}
                                        data-test-id="currentTabId"
                                        onClick={() => this.setState({ currentTab: index })}
                                    >{item.label}</Typography>
                                    {(index == this.state.currentTab) ? <Box style={{ backgroundColor: "#4640DE", height: "4px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", marginTop: "8px" }}>
                                    </Box> : null}
                                </Box>
                            )}
                        </Box>
                        {(this.state.currentTab == 0) && this.renderTabOne()}
                        {(this.state.currentTab == 1) && this.renderTabTwo()}
                        {(this.state.currentTab == 2) && this.renderTabThird()}
                        {(this.state.currentTab == 3) && this.renderTabForth()}
                    </Box>
                </Box>
            </MainContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const cardStyle = {
    backgroundColor: "#F3F5F9",
    display: "flex",
    borderRadius: "12px",
    justifyContent: "space-between",
    width: "1239px",
    padding: "16px",
    height: "204px",
    marginTop: '10px',
    "@media (max-width: 375px)": {
        backgroundColor: "#FFFFFF",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "335px",
        padding: "16px",
        height: "317px",
        marginTop: "10px",
    },
};
const tab2cardStyle = {
    justifyContent: "space-between",
    width: "1239px",
    borderRadius: "12px",
    alignItems: "center",
    padding: "16px",
    height: "204px",
    backgroundColor: "#F3F5F9",
    display: "flex",
    marginTop: '12px',
    "@media (max-width: 375px)": {
        backgroundColor: "#FFFFFF",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "335px",
        padding: "16px",
        height: "284px",
        marginTop: "10px",
    },
};

const tab3cardStyle = {
    borderRadius: "12px",
    width: "1239px",
    height: "204px",
    padding: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F3F5F9",
    display: "flex",
    marginTop: '10px',
    "@media (max-width: 375px)": {
        backgroundColor: "#FFFFFF",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "335px",
        padding: "16px",
        height: "284px",
        marginTop: "10px",
    },
};
const ContainerTab = styled(Popover)({
    width: "908px",
    height: "401px",
    left: "266px",
    top: '200px',
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
});
const Container = styled(Popover)({
    width: '976px%',
    height: '738px',
    overflowX: 'hidden',
    overflow: "hidden",
    padding: '20px',
    display: 'flex',
    gap: "20px",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        padding: '10px',
    },
    '& .box1': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        backgroundColor: '#F1F5F9',
        width: "976px",
        height: "738px",
        padding: "1rem"
    },
    '& .card': {
        display: "flex",
        width: "full",
        gap: "2rem",
        alignItems: "center",
    },
    '& .CardContent': {
        display: "flex",
        height: "170px",
        width: "auto",
        flexDirection: "column",
        backgroundColor: "#F1F5F9",
        gap: "10px",
        borderRadius: "8px",
        borderWidth: "1px",
        marginBottom: "1rem"
    },
    '& .userName': {
        width: "125px",
        height: "36px",
        color: "#141414",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "150%",
        fontFamily: "Manrope",
    },
    '& .body2': {
        display: "flex",
        width: "537px",
        height: "64px",
        gap: "16px"
    },
    "& .texts": {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%",
        color: "#141414B2"
    }
});

const MainContainer = styled(Box)({
    justifyContent: "center",
    overflowX: "hidden",
    width: "100%",
    "@media (max-width: 375px)": {
        backgroundColor: '#F3F5F9'
    },
    "& .navBoxContainer": {
        borderBottom: "1px solid #C1C7CD",
        height: "74px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        },
        "@media (max-width: 768px)": {
            justifyContent: "space-around",
            padding: "0px",
        },

    },
    "& .navBox": {
        alignItems: "center",
        width: "1200px",
        display: "flex",
        justifyContent: "space-between",
        height: "74px",
        "@media (max-width: 768px)": {
            justifyContent: "space-around",
            padding: "0px"
        },
    },
    "& .nameBox": {
        justifyContent: "center",
        display: "flex",
        maxWidth: "213px",
        alignItems: "center",
        borderLeft: "1px solid #D6DDEB",
        height: "48px",
        width: "100%",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .userIcon": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },
    "& .nameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#39393F"
    },
    "& .RequestText": {
        fontWeight: 700,
        lineHeight: "150%",
        fontSize: "16px",
        fontFamily: "Manrope",
        color: "#141414",
        ...(window.innerWidth <= 375 && {
            fontSize: "12.44px",
        }),
    },
    "& .RequestTexts": {
        fontWeight: 700,
        lineHeight: "150%",
        fontSize: "16px",
        fontFamily: "Manrope",
        color: "#141414",
        ...(window.innerWidth <= 375 && {
            fontWeight: 700,
            lineHeight: "150%",
            fontSize: "9.96px",
            fontFamily: "Manrope",
            color: "#141414",
        }),
    },
    "& .spanText": {
        fontWeight: 400,
        fontSize: "12.44px",
        fontFamily: "Manrope",
        color: "#141414B2",
        lineHeight: "150%",
        marginTop:'4px !important',
        "@media (max-width: 375px)": {
            fontWeight: 400,
            fontSize: "10px",
            fontFamily: "Manrope",
            color: "#141414B2",
            lineHeight: "150%",
        },
    },
    "& .spanTexts": {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Manrope",
        color: "#141414B2",
        lineHeight: "150%",
        width: '300px',
        "@media (max-width: 375px)": {
            fontWeight: 400,
            fontSize: "10px",
            fontFamily: "Manrope",
            color: "#141414B2",
            lineHeight: "150%",
        },
    },
    "& .adminText": {
        fontSize: "24px",
        color: "#25324B",
        lineHeight: "28.8px",
        fontWeight: 700,
        paddingBottom: "24px",
        fontFamily: "Manrope",
        paddingTop: "65px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .ContentContainer": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
    },
    "& .alignContainer": {
        flexDirection: "column",
        maxWidth: "1200px",
        width: "100%",
        paddingBottom: "1rem",
        display: "flex",
        paddingTop: "1rem",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        }
    },

    "& .tabsText": {
        lineHeight: "25.6px",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Manrope",
        color: "#7C8493"
    },

    "& .requestText": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.6px",
        color: "#25324B",
        "@media (max-width: 375px)": {
            fontSize: "12.44px"
        },
    },
    "& .tabsBox": {
        width: "106%",
        display: "flex",
        gap: "40px",
        flexWrap: "wrap",
        borderBottom: "1px solid #D6DDEB",
        "@media (max-width: 375px)": {
            alignItems: 'center',
            gap: '10px',
            overflowY: 'auto',
            padding: '10px',
            maxHeight: '300px',
        },
    },
    "& .heading": {
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        width: '1320px',
        height: "29px",
        top: '138px',
        left: '60px',
        paddingTop: "60px",
        "@media (max-width: 375px)": {
            padding: '23px'
        },
    },
    "& .headingg": {
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        width: '1320px',
        height: "29px",
        top: '139px',
        left: '60px',
        marginBottom: '20px',
        "@media (max-width: 768px)": {
            marginTop: '26px'
        },
        "@media (max-width: 375px)": {
            padding: '20px'
        }
    },
    "& .textBox": {
        display: "flex",
        justifyContent: "start",
        alignItem: "center ",
        textAlign: "center",
    },
    "& .msgBtn": {
        width: '60px',
        height: "43px",
        backgroundColor: "#5E70DD!important",
        borderRadius: "10px"
    },
    "& .paidBtn": {
        maxWidth: "203px",
        width: "100%",
        minHeight: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD!important",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "capitalize",
        "@media (max-width: 768px)": {
            width: "210px",
        }
    },
    "& .paidBtnn": {
        maxWidth: "262px",
        width: "100%",
        minHeight: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD!important",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "capitalize",
        "@media (max-width: 375px)": {
            width: "198px",
            height: '32px',
            fontSize: "12px",
        }
    },
    "& .detailsbox": {
        width: '503px',
        height: '319px',
        gap: '10px',
        "@media (max-width: 375px)": {
            height: '149px',
            gap: '4'
        }
    },
    "& .maindetailsbox": {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: "57px",
        "@media (max-width: 375px)": {
            flexDirection: 'column',
            height: '217px',
            padding: '20px'
        }
    },
    "& .profileimg": {
        width: '223px',
        height: '267px',
        borderRadius: '10px',
        "@media (max-width: 375px)": {
            width: '95px',
            height: '133px',
            borderRadius: '10px',
        }
    },
    "& .nameboxx": {
        width: '150px',
        height: '36px',
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 500,
        "@media (max-width: 375px)": {
            width: '90px',
            height: '22px',
            fontFamily: "Manrope",
            fontSize: "14px",
            fontWeight: 600,
            color: '#141414',
            marginTop: '4px'
        }
    },
    "& .btnBox": {
        width: '69.12px',
        height: '83px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .btnBoxtab": {
        width: '111px',
        height: '83px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .imgBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '62.84px',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF'
    },
    "& .imgBox1": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '62.84px',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        padding: "1rem 0"
    },
    "& .textbtn": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "21px",
        color: '#11141A',
        paddingTop: '5px'
    },
    "& .popupsidetext": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        color: '#121212',
    },
    "& .boxtextt": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .CardContents": {
        flex: 1,
        "@media (max-width: 768px)": {
            backgroundColor: '#FFFFFF'
        }
    },
    "& .secBox": {
        display: "flex",
        gap: "2rem",
        justifyContent: 'center',
        '@media (max-width: 375px)': {
            display: "flex",
            jjustifyContent: 'center',
            gap: "2rem",
        },
    },
    "& .beastbox": {
        paddingTop: '50px',
        '@media (max-width: 375px)': {
            paddingTop: '0px'
        },
    }
})
// Customizable Area End

export default UserProfileDetails;
