import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from "@mui/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';

import ConfirmServicesController, { Props } from "./ConfirmServicesController";

import { logo, calenderIcon, successCheckIcon, handShakeIcon, brightnessIcon } from "./assets"
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import FormField from "./components/FormField";
import TimeRangeFields from './components/TimeRangeFields';




const StyledTypo1 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important",
        height: "16px !important"
    }
});

const StyledTypo2 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
    }
});

const StyledTypo3 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
    }
});

const StyledTypo4 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "18px !important",
        textAlign: "center"
    }
});

const StyledTypo5 = styled(Typography)({
    "@media (max-width: 500px)": {
        width: "295px !important",
        height: "100px !important"
    }
});

const StyledTypo6 = styled(Typography)({
    "@media (max-width: 500px)": {
        width: "295px !important",
        height: "100px !important"
    }
});


const FilterSelectBoxes = styled(Box)({
    "@media (max-width: 750px)": {
        flexDirection: "column",
        paddingLeft: "0px !important",
        gap: "10px !important",
    },
    "@media (max-width: 500px)": {
        margin: "0px 30px !important"
    }
});

const FilterSelectBoxesContainer = styled(Box)({
    "@media (max-width: 1000px)": {
        height: "auto !important",
        flexDirection: "column",
        padding: "10px 0px !important",
    },
});

const StyledBox1 = styled(Box)({
    "@media (max-width: 750px)": {
        flexDirection: "column",
        alignItems: "center"
    },
});

const StyledBox2 = styled(Box)({
    "@media (max-width: 650px)": {
        marginBottom: "10px !important"
    },
    "@media (max-width: 500px)": {
        marginBottom: "30px !important",
        margin: "0px 30px !important"
    }
});

const StyledBox3 = styled(Box)({
    "@media (max-width: 500px)": {
        margin: "10px 0px !important",
        gap: "15px !important"
    },
});

const StyledBox4 = styled(Box)({
    "@media (max-width: 500px)": {
        margin: "10px 0px !important",
        gap: "15px !important"
    },
});

const StyledBox5 = styled(Box)({
    "@media (max-width: 500px)": {
        padding: "0px 20px !important",
        height: "50px !important"
    },
});

const StyledTextField = styled(TextField)({
    "@media (max-width: 500px)": {
        width: "294px !important",
        height: "33px !important",
        background: "#FFFFFF",
        "& .MuiInputBase-input": {
            fontSize: "12px"
        }
    },
});

const ConfirmReqButton = styled(Button)({
    "@media (max-width: 500px)": {
        borderRadius: "6.5px !important",
        width: "117px !important",
        height: "28px !important",
        fontSize: "10px !important",
    }
});

const SignUpDailyCheckInsButton = styled(Button)({
    "@media (max-width: 500px)": {
        width: "250px !important",
        fontSize: "14px !important",
    }
});

const SignUpBiWeeklyPhoneBuddyButton = styled(Button)({
    "@media (max-width: 500px)": {
        width: "340px !important",
        fontSize: "14px !important",
    }
});

const StyledNotAtThiSTimeButton = styled(Button)({
    width: "250px", height: "45px",
    "@media (max-width: 500px)": {
        width: "80%",
        fontSize: "14px !important",
    }
});


export default class ConfirmServices extends ConfirmServicesController {
    constructor(props: Props) {
        super(props);

    }

    

    CalendarIcon = () => (
        <img src={calenderIcon.default} alt="Calendar" />
    );

    renderSuccessPage() {
        return (
            <Box style={{
                display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"
            }}>
                <Box style={{
                    display: "flex", flexDirection: "column", alignItems: "center", gap: "30px"
                }}>
                    <Box style={{
                        display: "flex", alignItems: "center", flexDirection: "column", gap: "15px"
                    }}>
                        <img src={successCheckIcon.default} />
                        <StyledTypo4 style={{
                            fontFamily: "Manrope", fontWeight: 700, fontSize: "21.6px",
                            color: "#5E70DD", height: "30px", width: "103px"
                        }}>
                            SUCCESS
                        </StyledTypo4>
                    </Box>
                    <StyledTypo5 style={{
                        fontFamily: "Manrope", fontWeight: 500, fontSize: "17.53px",
                        color: "#4A4A4A", height: "54px", width: "495px", textAlign: "center"
                    }}>
                        Some of these services are available in your area! We will be in touch shortly to confirm and schedule.
                    </StyledTypo5>
                    <Button style={{
                        width: "261px", height: "45px", borderRadius: "12px",
                        background: "#5E70DD", color: "#FFFFFF", textTransform: "none",
                        fontFamily: "Roboto", fontSize: "17.53px", fontWeight: 600,
                    }}
                        data-test-id="goHomeScreenFromSuccess"
                        onClick={() => this.goToHomeScreenFromSuccess()}
                    >
                        Return to Home Page
                    </Button>
                </Box>
            </Box>
        )
    }

    renderFailurePage() {
        return (
            <Box style={{
                display: "flex", alignItems: "center", justifyContent: "center", flex : 1,
            }}>
                <Box style={{
                    display: "flex", flexDirection: "column", alignItems: "center", gap: "50px"
                }}>
                    <Box style={{
                        display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"
                    }}>
                        <StyledTypo6 style={{
                            fontFamily: "Manrope", fontWeight: 300, fontSize: "17.53px",
                            color: "#4A4A4A", height: "54px", width: "495px", textAlign: "center"
                        }}>
                            We don’t yet offer in-person services in your area. We’ll reach out as soon as this changes.
                        </StyledTypo6>
                        <StyledTypo6 style={{
                            fontFamily: "Manrope", fontWeight: 300, fontSize: "17.53px",
                            color: "#4A4A4A", height: "54px", width: "495px", textAlign: "center"
                        }}>
                            We do offer Daily Check-Ins or a Bi-Weekly Phone Buddy service across the US. Would you like to sign up for one of these?
                        </StyledTypo6>
                    </Box>
                    <StyledBox1 style={{ display: "flex", gap: "25px" }}>
                        <SignUpDailyCheckInsButton style={{
                            width: "320px", height: "45px", borderRadius: "12px",
                            background: "#5E70DD", color: "#FFFFFF", textTransform: "none",
                            fontFamily: "Roboto", fontSize: "17.53px", fontWeight: 600,
                        }}
                        >
                            Sign Up for Daily Check-Ins
                        </SignUpDailyCheckInsButton>
                        <SignUpBiWeeklyPhoneBuddyButton style={{
                            width: "410px", height: "45px", borderRadius: "12px",
                            background: "#5E70DD", color: "#FFFFFF", textTransform: "none",
                            fontFamily: "Roboto", fontSize: "17.53px", fontWeight: 600,
                        }}
                        >
                            Sign Up for Bi-Weekly Phone Buddy
                        </SignUpBiWeeklyPhoneBuddyButton>
                    </StyledBox1>
                    <StyledNotAtThiSTimeButton style={{
                      borderRadius: "12px",
                        background: "#FFFFFF", color: "#5E70DD", textTransform: "none",
                        fontFamily: "Roboto", fontSize: "17.53px", fontWeight: 600,
                        border: "1px solid #5E70DD"
                    }}
                        data-test-id="goHomeScreenFromFail"
                        onClick={() => this.goToHomeScreenFromFailure()}
                    >
                        Not at this time
                    </StyledNotAtThiSTimeButton>
                </Box>
            </Box>
        )
    }

    renderUserFields() {
        const { serviceRequest, zipError, isCalendarOpen, } = this.state;
    
        return (
            <StyledBox3
                style={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",
                    margin: "25px 0px"
                }}
            >
                <FormField
                    label="First Name"
                    data-test-id="firstName"
                    placeholder="First Name"
                    value={serviceRequest.firstName}
                    onChange={(e) => this.handleInputChange("firstName", e.target.value)}
                />
    
                <FormField
                    label="Last Name"
                     data-test-id="lastName"
                    placeholder="Last Name"
                    value={serviceRequest.lastName}
                    onChange={(e) => this.handleInputChange("lastName", e.target.value)}
                />
    
                <FormField
                    label="Cell Number"
                    placeholder="Cell Number"
                    data-test-id="phoneNumber"
                    testId="userCellNumberTestBtn"
                    value={serviceRequest.phoneNumber}
                    onChange={(e) => this.handleInputChange("phoneNumber", e.target.value)}
                    error={!this.state.serviceRequest.phoneNumberValid}
                    errorText={!this.state.serviceRequest.phoneNumberValid ? this.cellNumberHelperText1() : ""}
                />
    
                <FormField
                    label="Address"
                     data-test-id="address"
                    placeholder="Address"
                    value={serviceRequest.address}
                    onChange={(e) => this.handleInputChange("address", e.target.value)}
                />
    
                <FormField
                    label="Zip Code"
                    placeholder="Zip Code"
                    value={serviceRequest.postalCode}
                    onChange={this.handleZipCodeChange}
                    error={zipError}
                />
    
                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                    <StyledTypo1
                        style={{
                            width: "fit-content",
                            height: "26px",
                            textAlign: "start",
                            fontWeight: 600,
                            color: "#515B6F",
                            fontSize: "16px",
                            fontFamily: "Manrope",
                        }}
                    >
                        Preferred Start Date
                    </StyledTypo1>
    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            data-test-id="prefered_start_date"
                            open={isCalendarOpen}
                            value={serviceRequest.startDate}
                            minDate={dayjs()}
                            onChange={(newValue) => this.handleInputChange("startDate", newValue)}
                            onClose={() => this.setState({ isCalendarOpen: false })}
                            sx={{
                                width: "335px",
                                height: "50px",
                                justifyContent: "center",
                                border: "1px solid #D6DDEB",
                                borderRadius: "8px",
                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                input: {
                                    fontFamily: "Manrope",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    color: '#010101',
                                },
                                "@media (max-width: 500px)": {
                                    height: "33px !important",
                                    width: "294px !important",
                                    background: "#FFFFFF",
                                    "& .MuiInputBase-input": { fontSize: "12px" }
                                },
                            }}
                            slots={{ openPickerIcon: this.CalendarIcon }}
                            slotProps={{
                                textField: {
                                    placeholder: "MM/DD/YYYY",
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        this.setState({ isCalendarOpen: true });
                                    },
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ margin: 0 }}>
                                                <img src={calenderIcon.default} />
                                            </InputAdornment>
                                        ),
                                    },
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Box>

                <TimeRangeFields
                    startTime={serviceRequest.startTime}
                    endTime={serviceRequest.endTime}
                    onStartTimeChange={this.handleStartTimeChange}
                    onEndTimeChange={this.handleEndTimeChange}
                />
            </StyledBox3>
        );
    }
    

    renderRecipientFields() {
        const { serviceRequest } = this.state;
    
        return (
            <StyledBox3
                style={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",
                    margin: "25px 0px"
                }}
            >
                <FormField
                    data-test-id="receipentFirstName"
                    label="Recipient’s First Name"
                    placeholder="First Name"
                    value={serviceRequest.recipientFirstName}
                    onChange={(e) => this.handleInputChange("recipientFirstName", e.target.value)}
                />
    
                <FormField
                data-test-id="receipentLastName"
                    label="Recipient’s Last Name"
                    placeholder="Last Name"
                    value={serviceRequest.recipientLastName}
                    onChange={(e) => this.handleInputChange("recipientLastName", e.target.value)}
                />
    
                <FormField
                data-test-id="receipentPhoneNumber"
                    label="Recipient’s Cell Number"
                    placeholder="Cell Number"
                    testId="userCellNumberTestBtn"
                    value={serviceRequest.recipientPhoneNumber}
                    onChange={(e) => this.handleInputChange("recipientPhoneNumber", e.target.value)}
                    error={!this.state.serviceRequest.recipientPhoneNumberValid}
                    errorText={!this.state.serviceRequest.recipientPhoneNumberValid ? this.cellNumberHelperText1() : ""}
                />
            </StyledBox3>
        );
    }
    

    render() {
        const savedServiceRequest = JSON.parse(localStorage.getItem('serviceRequest') || '{}');

        const {index, selectedService, zipCode, preferedDate} = savedServiceRequest;

        const requestType =  ["Phone Check-Ins","In-Person Help"]

         

        dayjs.extend(updateLocale);
        dayjs.updateLocale('en', {
            weekStart: 1,
        });
        return (
            <Box style={{ display: "flex", flexDirection: "column", flex : 1 }}>
                <Box style={{
                    height: "74px", display: "flex", alignItems: "center",
                    borderBottom: "1px solid #C1C7CD", gap: "16px", padding: "0px 0px 0px 40px"
                }}>
                    <Box style={{ display: "flex", alignItems: "center", gap: "40px" }}>
                        <img
                            src={logo.default}
                            alt="senior_year"
                            style={{ width: "195px", height: "21px" }}
                        />
                    </Box>
                </Box>
                {this.state.checkIfReqIsConfirmed === "initial" && (
                    <Box style={{
                        display: "flex", flexDirection: "column", minHeight: "100vh",
                        gap: "20px", marginTop: "20px", height: "auto"
                    }}>
                        <Typography style={{ ...webStyle.confirmServicesHading }}>
                            Confirm Services
                        </Typography>
                        <FilterSelectBoxesContainer style={{
                            display: "flex", height: "108px", borderRadius: "13px",
                            margin: "0px 60px", background: "#F1F5F9", alignItems: "center",
                            gap: "20px", padding: "0px 20px", justifyContent: "space-between",
                        }}>
                            <FilterSelectBoxes style={{ display: "flex", gap: "20px" }}>
                                <Select
                                    defaultValue={1}
                                    style={webStyle.selectBox}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={handShakeIcon.default} />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        "@media (max-width: 500px)": {
                                            width: "130px !important",
                                            height: "30px !important",
                                            fontSize: "10px !important",
                                            borderRadius: "4px !important"
                                        }
                                    }}
                                >
                                    <MenuItem key={1} value={1} >{requestType[index]}</MenuItem>
                                </Select>
                                <Select
                                    defaultValue={1}
                                    style={webStyle.selectBox}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <HeadphonesOutlinedIcon style={{ color: "#393939" }} />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        "@media (max-width: 500px)": {
                                            width: "130px !important",
                                            height: "30px !important",
                                            fontSize: "10px !important",
                                            borderRadius: "4px !important"
                                        }
                                    }}
                                >
                                    <MenuItem key={1} value={1} >{selectedService}</MenuItem>
                                </Select>
                            </FilterSelectBoxes>
                            <ConfirmReqButton style={{
                                width: "177px", height: "39px", borderRadius: "10px", color: "#FFFFFF",
                                background: "#5E70DD", fontFamily: "Roboto", fontSize: "16px",
                                fontWeight: 700, textTransform: "none",
                            }}
                                data-test-id="confirmReqBtn"
                                disabled={this.isCreateBookingDisabled()}
                                onClick={() => this.handleConfirmRequest()}
                            >
                                Confirm Request
                            </ConfirmReqButton>
                        </FilterSelectBoxesContainer>
                        <StyledBox2 style={{ display: "flex", margin: "0px 60px", }}>
                            <Typography style={{
                                fontFamily: "Manrope", fontWeight: 600, fontSize: "16px",
                                color: "#000000", height: "22px",
                            }}>
                                Are you booking services for yourself or someone else?
                            </Typography>
                        </StyledBox2>
                        <RadioGroup
                            sx={{
                                margin: "0px 60px",
                                "@media (max-width: 500px)": {
                                    margin: "0px 30px !important",
                                },
                            }}
                            data-test-id="radioTestBtn"
                            value={this.state.selectYesOrNo} // Add this to control the component state
                            onClick={
                               ()=>{
                                if(this.state.isCalendarOpen){
                                    this.setState({ isCalendarOpen: false })
                                }
                               } 
                            }
                            onChange={(event) => {
                                this.handleYesOrNo(event);
                            }}
                        >
                            {/* <FormControlLabel value="Yes"   control={<Radio />} label="Yes" /> */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Radio 
                                    value="Yes" 
                                    checked={this.state.selectYesOrNo === "Yes"}
                                    onClick={(event) => event.stopPropagation()}
                                />
                                <span style={webStyle.radiotext}>MySelf</span>
                            </div>
                            {this.state.selectYesOrNo === "Yes" && (
                                this.renderUserFields()
                            )}
                             <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Radio 
                                    value="No" 
                                    checked={this.state.selectYesOrNo === "No"}
                                    onClick={(event) => event.stopPropagation()}
                                />
                                <span style={webStyle.radiotext}>Someone Else</span>
                            </div>
                            {this.state.selectYesOrNo === "No" && (
                                <Box>
                                    {this.renderRecipientFields()}
                                    {this.renderUserFields()}
                                </Box>
                            )}
                        </RadioGroup>
                    </Box>
                )}
                {this.state.checkIfReqIsConfirmed === "success" && this.renderSuccessPage()}
                {this.state.checkIfReqIsConfirmed === "fail" && this.renderFailurePage()}
                <StyledBox5 style={{
                    height: "88px", display: "flex", alignItems: "center", marginTop: "50px",
                    background: "#5E70DD", padding: "0px 60px", justifyContent: "space-between"
                }}>
                    <StyledTypo2 style={{
                        fontWeight: 400, color: "#FFFFFF", height: "16px",
                        width: "fit-content", fontFamily: "Manrope", fontSize: "16px",
                    }}>
                        CompanyName @ 202X. All rights reserved.
                    </StyledTypo2>
                    <StyledTypo3 style={{
                        width: "fit-content", 
                        fontFamily: "Manrope", 
                        fontSize: "16px",
                        fontWeight: 400, 
                        color: "#FFFFFF", 
                        height: "16px", 
                        cursor: "pointer"
                    }}
                        data-test-id="goTermsPageTestBtn"
                        onClick={() => this.goTermsConditions()}
                    >
                        Terms & Conditions
                    </StyledTypo3>
                </StyledBox5>
            </Box>
        );
    }
}

export const webStyle = {
    confirmServicesHading: {
        height: "24px", width: "fit-content", padding: "0px 60px",
        fontFamily: "Manrope", fontWeight: 600, fontSize: "17.5px",
        color: "#000000"
    },
    selectBox: {
        width: "193px",
        height: "50px",
        fontFamily: "Manrope",
        fontSize: "15px",
        fontWeight: 400,
        color: "#161C2D",
        border: "1px solid #E7E9ED",
        borderRadius: "8px",
        background: "#FFFFFF"
    },
    radiotext : {
        fontFamily: 'Manrope',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '100%',
        letterSpacing: '0%',
    }
}

// Customizable Area End
