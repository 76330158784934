import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components - export for reuse
const StyledTypo1 = styled(Typography)({
  width: "fit-content",
  height: "26px",
  textAlign: "start",
  color: "#515B6F",
  fontFamily: "Manrope",
  fontWeight: 600,
  fontSize: "16px"
});

const StyledTextField = styled(TextField)({
  width: "335px",
  height: "50px",
  borderRadius: "8px",
  border: "1px solid #D6DDEB",
  justifyContent: "center",
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input': {
    fontWeight: 600,
    color: '#010101',
    fontFamily: "Manrope",
    fontSize: "16px",
  },
});

/**
 * Interface for FormField props
 */
interface FormFieldProps {
  label: string;
  placeholder: string;
  type?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorText?: string;
  testId?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  styles?: React.CSSProperties;
}

/**
 * Common FormField component to be used across the form
 */
const FormField: React.FC<FormFieldProps> = ({
  label,
  placeholder,
  type = "text",
  value,
  onChange,
  error = false,
  errorText = "",
  testId,
  onClick,
  styles = {}
}) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <StyledTypo1>
        {label}
      </StyledTypo1>
      <StyledTextField
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={error}
        data-test-id={testId}
        onClick={onClick}
        style={styles}
      />
      {error && errorText && (
        <Typography style={{
          width: "fit-content",
          textAlign: "start",
          color: "#DC2626",
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: "12px"
        }}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

export default FormField;