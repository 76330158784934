import React, { useState } from 'react';
import { Box, ClickAwayListener, styled, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

const StyledTypo1 = styled(Typography)({
  width: "fit-content",
  height: "26px",
  textAlign: "start",
  color: "#515B6F",
  fontFamily: "Manrope",
  fontWeight: 600,
  fontSize: "16px"
});

interface TimeRangeFieldsProps {
  startTime: string;
  endTime: string;
  onStartTimeChange: (newTime: string) => void;
  onEndTimeChange: (newTime: string) => void;
}

const TimeRangeFields: React.FC<TimeRangeFieldsProps> = ({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange
}) => {
  // State to manage open/closed state of time pickers
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);
  
  // Convert string times to Dayjs objects for the pickers
  const startTimeDayjs = startTime ? dayjs(startTime, 'HH:mm') : null;
  const endTimeDayjs = endTime ? dayjs(endTime, 'HH:mm') : null;

  // Handle time changes
  const handleStartTimeChange = (newTime: Dayjs | null) => {
    if (newTime) {
      // Ensure we preserve the original date but update the time
      const timeString = newTime.format('HH:mm');
      onStartTimeChange(timeString);
      // Close the picker after selection is complete
      setStartTimeOpen(false);
    } else {
      onStartTimeChange('');
    }
  };

  const handleEndTimeChange = (newTime: Dayjs | null) => {
    if (newTime) {
      // Ensure we preserve the original date but update the time
      const timeString = newTime.format('HH:mm');
      onEndTimeChange(timeString);
      // Close the picker after selection is complete
      setEndTimeOpen(false);
    } else {
      onEndTimeChange('');
    }
  };

  return (
    <>
      {/* Start Time Picker */}
      <Box style={{ display: "flex", flexDirection: "column", gap: "4px", marginBottom: "16px" }}>
        <StyledTypo1>
          Start Time
        </StyledTypo1>
        <ClickAwayListener onClickAway={() => setStartTimeOpen(false)}>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={startTimeDayjs}
                onChange={handleStartTimeChange}
                data-test-id="startTimeSelector"
                open={startTimeOpen}
                onOpen={() => setStartTimeOpen(true)}
                onClose={() => setStartTimeOpen(false)}
                sx={{
                  width: "335px",
                  height: "50px",
                  border: "1px solid #D6DDEB",
                  borderRadius: "8px",
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: '#010101',
                  }
                }}
                slotProps={{
                  textField: {
                    placeholder: "Select start time",
                    onClick: () => setStartTimeOpen(true)
                  }
                }}
                ampm
                minutesStep={15}
                views={['hours', 'minutes']}
              />
            </LocalizationProvider>
          </div>
        </ClickAwayListener>
      </Box>

      {/* End Time Picker */}
      <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <StyledTypo1>
          End Time
        </StyledTypo1>
        <ClickAwayListener onClickAway={() => setEndTimeOpen(false)}>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={endTimeDayjs}
                data-test-id="endTimeSelector"
                onChange={handleEndTimeChange}
                open={endTimeOpen}
                onOpen={() => setEndTimeOpen(true)}
                onClose={() => setEndTimeOpen(false)}
                sx={{
                  width: "335px",
                  height: "50px",
                  border: "1px solid #D6DDEB",
                  borderRadius: "8px",
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: '#010101',
                  }
                }}
                slotProps={{
                  textField: {
                    placeholder: "Select end time",
                    onClick: () => setEndTimeOpen(true)
                  }
                }}
                ampm
                minutesStep={15}
                views={['hours', 'minutes']}
              />
            </LocalizationProvider>
          </div>
        </ClickAwayListener>
      </Box>
    </>
  );
};

export default TimeRangeFields;