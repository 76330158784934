import React from 'react';
import { TextField, styled } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

// Define a type for the path strings in your form data
type FormFieldPath = 
  | 'recommender1.fullName' 
  | 'recommender1.email' 
  | 'recommender2.fullName' 
  | 'recommender2.email'
  | 'guardian.fullName'
  | 'guardian.email'
  | 'guardian.phone'
  | 'advisor.fullName'
  | 'advisor.email'
  | 'advisor.title'
  | 'advisor.phone';

// Base styled TextField component matching your original styling
const StyledBaseTextField = styled(TextField)({
  width: '360px',
  '& .MuiOutlinedInput-root': {
    height: '56px',
    borderRadius: '10px',
    outline: 'none',
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  "@media (max-width: 900px)": {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: "33px !important",
    },
    background: "#FFFFFF",
    "& .MuiInputBase-input": {
      fontSize: "10.7px",
      padding: "8px 12px"
    }
  }
});

// Interface for our enhanced TextField props
interface FormTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  fieldPath: FormFieldPath;
  value: string | undefined;
  onChange: (field: string, value: any) => void;
  error?: boolean;
}

// The enhanced TextField component
export const FormTextField: React.FC<FormTextFieldProps> = ({
  fieldPath,
  value,
  onChange,
  error,
  helperText,
  disabled,
  placeholder,
  sx,
  ...props
}) => {
  // Default styles to apply to all text fields
  const defaultSx = {
    input: {
      color: '#515B6F', 
      fontWeight: 600,
      fontSize: "16px", 
      fontFamily: "Manrope"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D6DDEB',
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#010101",
    },
    ...sx
  };

  return (
    <StyledBaseTextField
      placeholder={placeholder}
      value={value || ""}
      onChange={(e) => onChange(fieldPath, e.target.value)}
      error={error}
      helperText={helperText}
      disabled={disabled}
      sx={defaultSx}
      {...props}
    />
  );
};