import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button, CardContent
} from "@mui/material";

import { close, girlimg, logo, msgicon, Receipt, search, shedule, userIcon } from "./assets"

import UserProfileController, { Props } from "./UserProfileController";
// Customizable Area End

class StudentUserDetailsPage extends UserProfileController {
    //   static SocialMediaAccount: SocialMediaAccount;

    constructor(props: Props) {
        super(props);

    }

    // Customizable Area Start
    card() {
        return (
            <CardContent
                style={{ flex: 1 }
                }
            >
                <Typography
                    className="textBox">
                    <strong
                        className="RequestText">
                        Booking Request ID:</strong>
                    <strong className="spanText">008928340948 </strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Service:</strong>
                    <strong className="spanText">Tech Help</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Address:</strong>
                    <strong className="spanText"> 13th Street, 47 W 13th St, New York, NY 10011</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Start Date:</strong>
                    <strong className="spanText">14 Aug</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong  className="RequestText">Frequency:</strong>
                    <strong className="spanText"> Once</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Start Time:</strong>
                    <strong className="spanText"> 11:00 AM</strong>
                </Typography>
                <Typography variant="body1"
                    className="textBox">
                    <strong className="RequestText">End Time:</strong>
                    <strong className="spanText"> 12:00 PM</strong>
                </Typography>
            </CardContent>
        )
    }

    cardfive() {
        return (
            <CardContent
                style={{ flex: 1 }
                }
            >
                <Typography
                    className="textBox">
                    <strong
                        className="RequestText">
                        Booking Request ID:</strong>
                    <strong className="spanText">008928340948 </strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Service:</strong>
                    <strong className="spanText">Tech Help</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Address:</strong>
                    <strong className="spanTexts"> 13th Street, 47 W 13th St, New York, NY 10011</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Start Date:</strong>
                    <strong className="spanText">14 Aug</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Frequency:</strong>
                    <strong className="spanText"> Once</strong>
                </Typography>
                <Typography variant="body1" className="textBox">
                    <strong className="RequestText">Start Time:</strong>
                    <strong className="spanText"> 11:00 AM</strong>
                </Typography>
                <Typography variant="body1"
                    className="textBox">
                    <strong className="RequestText">End Time:</strong>
                    <strong className="spanText"> 12:00 PM</strong>
                </Typography>
                <Typography variant="body1"
                    className="textBox">
                    <strong className="RequestText">Decline Note:</strong>
                    <strong className="spanText">  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et cursus purus. Duis venenatis metus orci, eu interdum tortor laoreet eu.</strong>
                </Typography>
            </CardContent>
        )
    }

    renderTabOne() {
        return (
            <Box
                style={{
                    padding: "28px 0px"
                }}>
                <Box
                    style={cardStyle}
                >
                    {this.card()}
                </Box>

                <Box style={cardStyle}>
                    {this.card()}
                </Box>
            </Box>)
    }

    renderTabTwo() {
        return (
            <Box>
                <Box style={tab2cardStyle}>
                    {this.card()}
                    <Box style={{ display: "flex", gap: "2rem" }}>
                        <Box className="btnBox">
                            <Box className="imgBox">
                                <img src={shedule.default} alt="" />
                            </Box>
                            <Typography className="textbtn">Reschedule</Typography>
                        </Box>
                        <Box className="btnBox">
                            <Box className="imgBox">
                                <img src={close.default} alt="" />
                            </Box>
                            <Typography className="textbtn">Cancel</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
    renderTabThird() {
        return (
            <Box>
                {[...Array(3)].map((_, index) => (
                    <Box key={index} style={tab3cardStyle}>
                        {this.card()}
                        <Box style={{ display: "flex", gap: "5rem", paddingRight: "2rem" }}>
                            <Box className="btnBox">
                                <Box className="imgBox">
                                    <img src={Receipt.default} alt="" />
                                </Box>
                                <Typography className="textbtn">See Receipt</Typography>
                            </Box>
                            <Box className="btnBoxtab">
                                <Box className="imgBox1">
                                    <img src={search.default} alt="" />
                                </Box>
                                <Typography className="textbtn">Rebook this Service</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        )
    }
    renderTabForth() {
        return (
            <Box>
                <Box style={tab2cardStyle}>
                    {this.card()}
                    <Box style={{ display: "flex" }}>
                        <Box className="btnBoxtab">
                            <Box className="imgBox">
                                <img src={search.default} alt="" />
                            </Box>
                            <Typography className="textbtn">Rebook this Service</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
    renderTabFive() {
        return (
            <Box>
                <Box style={tab2cardStyle}>
                    {this.cardfive()}
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        let { StudentTab } = this.state

        return (
            <MainContainer>
                <Box className="navBoxContainer">
                    <Box className="navBox">
                        <img src={logo.default} />
                        <img src={userIcon.default} className="userIcon" />
                        <Box className="nameBox">
                            <Typography className="nameText">{ }</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="ContentContainer">
                    <Box className="alignContainer">
                        <Box className="heading">
                            <Typography style={{
                                color: "#25324B",
                                fontWeight: 700,
                                fontFamily: "Manrope",
                                lineHeight: "120%",
                                fontSize: "24px",
                            }}>
                                Student Profile
                            </Typography>
                        </Box>
                        <Box className="maindetailsbox">
                            <Box className='secBox'>
                                <img src={girlimg.default} alt="" className="profileimg" />
                                <Box className="detailsbox">
                                    <Typography className="nameboxx"> Martha Brown</Typography>
                                    <Box className='beatbox' >
                                        <Box className="textBox"> <Typography className="RequestTexts">Email: </Typography>
                                            <Typography className="spanText">your.name@email.com </Typography></Box>
                                        <Box className="textBox"> <Typography className="RequestTexts"> Cell Number:</Typography>
                                            <Typography className="spanText"> (201) 555-1111</Typography></Box>
                                        <Box className="textBox"> <Typography className="RequestTexts">Address: </Typography>
                                            <Typography className="spanText">  13th Street. 47 W 13th St, New York, NY 10011</Typography></Box>
                                        <Box className="textBox"> <Typography className="RequestTexts">Birth Date: </Typography>
                                            <Typography className="spanText"> 01/01/2001</Typography></Box>
                                        <Box className="textBox"> <Typography className="RequestTexts">Age: </Typography>
                                            <Typography className="spanText"> 23</Typography></Box>
                                        <Box className="textBox"> <Typography className="RequestTexts">School: </Typography>
                                            <Typography className="spanText"> Riverdale Country School</Typography></Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <Box style={{ display: 'flex', gap: "2rem" }}>
                                    <Button className="msgBtn">
                                        <img src={msgicon.default} />
                                    </Button>
                                </Box>

                            </Box>
                        </Box>
                        <Box className="headingg">
                            <Typography style={{
                                fontFamily: "Manrope",
                                fontSize: "24px",
                                fontWeight: 700,
                                lineHeight: "120%",
                                color: "#25324B"
                            }}>
                                Bookings
                            </Typography>
                        </Box>
                        <Box className="tabsBox">
                            <Box className="tabsBox">
                                {StudentTab.map((item, index) =>
                                    <Box>
                                        <Typography className="tabsText" style={{ color: `${(index == this.state.currentTabs) ? "#25324B" : "#7C8493"}` }}
                                            data-test-id="currentTabId"
                                            onClick={() => this.setState({ currentTabs: index })}
                                        >{item.label}</Typography>
                                        {(index == this.state.currentTabs) ? <Box style={{ backgroundColor: "#4640DE", height: "4px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", marginTop: "8px" }}>
                                        </Box> : null}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {(this.state.currentTabs == 0) && this.renderTabOne()}
                        {(this.state.currentTabs == 1) && this.renderTabTwo()}
                        { (this.state.currentTabs == 2) && this.renderTabThird ()}
                  { (this.state.currentTabs == 3) && this.renderTabForth ()}
                  { (this.state.currentTabs == 4) && this.renderTabFive ()}

                    </Box > 
                </Box >
            

        </MainContainer>
        );  
        // Customizable Are a End
    }
}

// Customizable Area Start
const cardStyle = {
    backgroundColor: "#F3F5F9",
    display: "flex",
    borderRadius: "12px",
    justifyContent: "space-between",
    width: "1239px",
    padding: "16px",
    height: "204px",
    marginTop: '10px',
    "@media (max-width: 375px)": {
        backgroundColor: "#FFFFFF",
        flexDirection: "column",
        justifyContent: "space-between",
    }
};
const tab2cardStyle = {
    justifyContent: "space-between",
    width: "1239px",
    borderRadius: "12px",
    alignItems: "center",
    padding: "16px",
    height: "204px",
    backgroundColor: "#F3F5F9",
    display: "flex",
    marginTop: '10px'
};

const tab3cardStyle = {
    borderRadius: "12px",
    width: "1239px",
    height: "204px",
    padding: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F3F5F9",
    display: "flex",
    marginTop: '10px'
};

const MainContainer = styled(Box)({
    justifyContent: "center",
    overflowX: "hidden",
    width: "100%",
    "@media (max-width: 375px)": {
            backgroundColor:'#F3F5F9'
        },
       "& .navBoxContainer": {
         borderBottom: "1px solid #C1C7CD",
        height: "74px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        },
        "@media (max-width: 768px)": {
            justifyContent: "space-around",
            padding: "0px",
        }
    },
    "& .navBox": {
        alignItems: "center",
        width: "1200px",
        display: "flex",
        justifyContent: "space-between",
        height: "74px",
        "@media (max-width: 768px)": {
            justifyContent: "space-around",
            padding: "0px"
        },
    },
    "& .nameBox": {
        justifyContent: "center",
        display: "flex",
        maxWidth: "213px",
        alignItems: "center",
        borderLeft: "1px solid #D6DDEB",
        height: "48px",
        width: "100%",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .RequestTexts": {
        fontWeight: 700,
        lineHeight: "150%",
        fontSize: "16px",
        fontFamily: "Manrope",
        color: "#141414",
        "@media (max-width: 375px)": {
            fontWeight: 700,
            lineHeight: "150%",
            fontSize: "9.96px",
            fontFamily: "Manrope",
            color: "#141414",
        },
    },
    "& .userIcon": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },
    "& .nameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#39393F"
    },
    "& .RequestText": {
        fontWeight: 700,
        lineHeight: "150%",
        fontSize: "16px impotant",
        fontFamily: "Manrope",
        color: "#141414",
       "@media (max-width: 375px)": {
            fontSize: "12.44px",
             fontWeight: 700,
            lineHeight: "150%",
            fontFamily: "Manrope",
            color: "#141414",
        },
    },
    "& .spanText": {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Manrope",
        color: "#141414B2",
        lineHeight: "150%",
        "@media (max-width: 375px)": {
            fontSize: "10px !impotant",
            overflowWrap: 'break-word',
            wordBreak: 'break-word', 
            wordWrap: 'break-word', 
          whiteSpace: 'normal'
        },
    },        
    "& .adminText": {
        fontSize: "24px",
        color: "#25324B",
        lineHeight: "28.8px",
        fontWeight: 700,
        fontFamily: "Manrope",
        paddingTop: "65px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .ContentContainer": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
    },
    "& .alignContainer": {
        flexDirection: "column",
        maxWidth: "1200px",
        width: "100%",
        paddingBottom: "1rem",
        display: "flex",
        paddingTop: "1rem",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        }
    },

    "& .tabsText": {
        lineHeight: "25.6px",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Manrope",
        color: "#7C8493"
    },

    "& .requestText": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.6px",
        color: "#25324B",
        paddingBottom: "12px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .tabsBox": {
        width: "106%",
        display: "flex",
        gap: "40px",
        flexWrap: "wrap",
        borderBottom: "1px solid #D6DDEB",
        "@media (max-width: 375px)": {
            alignItems: 'center',
            gap: '10px',
            overflowY: 'auto',
            padding: '10px',
            maxHeight: '300px',
        }
    },
    "& .spanTexts": {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Manrope",
        color: "#141414B2",
        lineHeight: "150%",
        width: '300px',
        "@media (min-width: 375px)": {
            fontSize: "9.96px !important"
        } 
    },
    "& .heading": {
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        width: '1320px',
        height: "29px",
        top: '138px',
        left: '60px',
        paddingTop: "60px",
        "@media (max-width: 375px)": {
            padding: '23px'
        },
    },
    "& .headingg": {
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        width: '1320px',
        height: "29px",
        top: '138px',
        left: '60px',
        marginBottom: '20px',
        "@media (max-width: 375px)": {
            padding: '20px'
        }
    },
    "& .textBox": {
        display: "flex",
        height:'23px',   
        fontFamily: "Manrope",
        fontWeight: "700",
        fontSize: " 12.44px",
        lineHeight: "150%",
        letterSpacing: "0%",  
    },
    "& .msgBtn": {
        width: '60px',
        height: "43px",
        backgroundColor: "#5E70DD!important",
        borderRadius: "10px"
    },
    "& .detailsbox": {
        width: '503px',
        height: '319px',
        gap: '10px',
        "@media (max-width: 375px)": {
            height: '149px',
            gap:'4'
        }
    }, 
    "& .maindetailsbox": {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: "57px",
        "@media (max-width: 375px)": {
            flexDirection: 'column',
            height: '217px',
            padding: '20px'
        }
    },
    "& .profileimg": {
        width: '223px',
        height: '267px',
        borderRadius: '10px',
        "@media (max-width: 375px)": {
            width: '95px',
            height: '133px',
            borderRadius: '10px',
        }
    },
    "& .nameboxx": {
        width: '150px',
        height: '36px',
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 500,
        "@media (max-width: 375px)": {
            width: '95px',
            height: '22px',
            fontFamily: "Manrope",
            fontSize: "14px",
            fontWeight: 600,
            color:'#141414',
            marginTop:'4px'
        } 
    }, 
    "& .btnBox": {
        width: '69.12px',
        height: '83px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .btnBoxtab": {
        width: '111px',
        height: '83px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .imgBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '62.84px',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF'
    },
    "& .imgBox1": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '62.84px',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        padding: "1rem 0"
    },
    "& .textbtn": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "21px",
        color: '#11141A',
        paddingTop: '5px'
    },
    "& .secBox": {
        display: "flex",
        gap: "2rem",
        justifyContent: 'center',
        '@media (max-width: 375px)': {
            display: "flex",
            jjustifyContent: 'center',
            gap: "2rem",
        },
    },

})

// Customizable Area End

export default StudentUserDetailsPage;
