import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentTab: number
    currentTabs:number;
    selectedTab:any;
    tabs: any[],
    StudentTab:any[];
    open:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    APiCallId: string = "";
    getBookingsCallId: string = ";"
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            currentTab: 0,
            currentTabs:0,
            open:false,
            selectedTab:'seniors',
            tabs: [
                { label: "Requests (2)" },
                { label: "Upcoming (1)" },
                { label: "Past (3)" },
                { label: "Canceled (1)" },
            ],
            StudentTab: [
                { label: "Requests (2)" },
                { label: "Upcoming (1)" },
                { label: "Past (3)" },
                { label: "Canceled (1)" },
                { label: "Declined (1)" },
            ],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
     handleOpen = () => {
        this.setState({ open: true });
      };
      Rescheduleopen = () => {
        this.setState({ open: true });
      };
    
      handleClose = () => {
        this.setState({ open: false });
      };
    // Customizable Area End

}

