import React from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button,
  IconButton,
  styled
} from '@mui/material';
import {upload, fileIcon} from "../assets";
import CloseIcon from '@mui/icons-material/Close';
import { FormTextField } from './FormTextField';

interface Recommender {
  fullName: string;
  email: string;
}

interface Guardian {
  fullName: string;
  email: string;
  phone: string;
}

interface Advisor {
  fullName: string;
  email: string;
  title: string;
  phone: string;
}

interface SchoolId {
  fileName: string;
  fileSize: string;
  fileType?:string;
  fileData?: string;
}

interface FormData {
  recommender1: Recommender;
  recommender2: Recommender;
  guardian: Guardian;
  advisor: Advisor;
  schoolId: SchoolId;
}

interface RecommendationsFormProps {
  formData: FormData;
  setFormData: (data: FormData) => void;
  showEditButton: boolean;
}

const StyledTextField = styled(TextField)({
  width: '360px',
  '& .MuiOutlinedInput-root': {
    height: '56px',
    borderRadius: '10px',
    outline : 'none',
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  "@media (max-width: 900px)": {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: "33px !important",
    },
    background: "#FFFFFF",
    "& .MuiInputBase-input": {
      fontSize: "10.7px",
      padding: "8px 12px"
    }
  }
});

const StyledBox = styled(Box)({
   flex: "1 1 400px", 
  minWidth: "300px",
  "@media (max-width: 400px)": {
    minWidth: "100%",
  }
})

const StyledHeader = styled(Typography)({
  fontFamily: "Manrope, sans-serif",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "28.8px",
  letterSpacing: "0%",
  marginBottom : "16px",
 "@media (max-width: 400px)": {
   fontSize : "16px"
 }
})

const StyledUploadSection = styled(Box)({
  mt: 3, 
  border: '1px dashed #bdbdbd', 
  borderRadius: "10px", 
  maxWidth : "530px",
  textAlign: 'center',
  backgroundColor: '#fafafa',
  display : "flex",
  alignItems : "center",
  flexWrap : "wrap",
  padding : "16px 24px 16px 32px",
  "@media (max-width: 400px)": {
   flexDirection : "column",
   gap : "10px"
 }
})

const StyledUploadArea = styled(Box)({
  display : "flex", 
  flex : 1, 
  alignItems : "center",
  "@media (max-width: 400px)": {
   flexDirection : "column",
   alignItems : "center",
   gap : "10px"
 }
})

const StyledButton = styled(Button)({
  marginLeft : "auto", 
  width : "98px", 
  height : "35px", 
  fontSize : "10px", 
  cursor : "pointer", 
  borderRadius : "5px" ,
  "@media (max-width: 400px)": {
    marginLeft : "0",
    
 }
})


// Create an enhanced StyledTextField component that accepts common props
const EnhancedTextField = styled(TextField)(({ theme }) => ({
  width: '360px',
  '& .MuiOutlinedInput-root': {
    height: '56px',
    borderRadius: '10px',
    outline: 'none',
    '& fieldset': {
      borderWidth: '1px',
      borderColor: '#D6DDEB',
    },
  },
  '& .MuiInputBase-input': {
    color: '#515B6F',
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Manrope",
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: "#010101",
  },
  "@media (max-width: 900px)": {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: "33px !important",
    },
    background: "#FFFFFF",
    "& .MuiInputBase-input": {
      fontSize: "10.7px",
      padding: "8px 12px"
    }
  }
}));







const RecommendationsForm: React.FC<RecommendationsFormProps> = (props) => {
  // Destructure props to get state and setState
  const { formData, setFormData, showEditButton } = props;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const dropAreaRef = React.useRef<HTMLDivElement>(null);
  const hasFile = formData.schoolId && formData.schoolId.fileName !== '';

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValidEmail = (email:string) => emailRegex.test(email);

  const [inputErrors, setInputErrors] = React.useState<{[key: string]: boolean}>({
    'recommender1.email': false,
    'recommender2.email': false,
    'guardian.email': false,
    'advisor.email': false,
    'guardian.phone': false,
    'advisor.phone': false
  });
  const [showPreview, setShowPreview] = React.useState<boolean>(false);

  const togglePreview = (): void => {
    setShowPreview(!showPreview);
  };
  
  const closePreview = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setShowPreview(false);
  };

  const handleFile = (file: File) => {
    // Validate file type
    const validTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (!validTypes.includes(file.type)) {
      alert("Please upload a JPG, PNG or PDF file");
      return;
    }
  
    // Validate file size (10MB)
    if (file.size > 10 * 1024 * 1024) {
      alert("File size should not exceed 10MB");
      return;
    }
  
    // Format file size for display
    let fileSize : string;
    if (file.size < 1024) {
      fileSize = `${file.size}B`;
    } else if (file.size < 1024 * 1024) {
      fileSize = `${(file.size / 1024).toFixed(1)}KB`;
    } else {
      fileSize = `${(file.size / (1024 * 1024)).toFixed(1)}MB`;
    }
    
    // Convert to base64 and update formData
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const updatedFormData = {
        ...formData,
        schoolId: {
          ...formData.schoolId,
          fileName: file.name,
          fileSize: fileSize,
          fileData: base64String,
          fileType: file.type
        }
      };
      setFormData(updatedFormData);
    };
    reader.readAsDataURL(file);
  }
  
  
  const handleInputChange = (field: string, value: any): void => {
    // Parse the field path (e.g., 'recommender1.email' → ['recommender1', 'email'])
    const pathParts = field.split('.');
    const section = pathParts[0];
    const property = pathParts[1];
    
    // Create updated formData with proper nesting
    const updatedFormData = { ...formData };
    
    // Handle specific field types
    if (property === 'email') {
      // Email validation
      const isValid = isValidEmail(value);
      setInputErrors({
        ...inputErrors,
        [field]: !isValid && value !== '' // Only show error if not empty
      });
      
      // Update the form data
      if (section && property && updatedFormData[section as keyof FormData]) {
        (updatedFormData[section as keyof FormData] as any)[property] = value;
      }
    } 
    else if (property === 'phone') {
      // Phone number formatting and validation
      const { formattedValue, isValid } = formatPhoneNumber(value);
      
      // Update error state
      setInputErrors({
        ...inputErrors,
        [field]: !isValid && formattedValue !== '' // Only show error if not empty
      });
      
      // Update the form data with formatted value
      if (section && property && updatedFormData[section as keyof FormData]) {
        (updatedFormData[section as keyof FormData] as any)[property] = formattedValue;
      }
    } 
    else {
      // Handle other fields normally
      if (section && property && updatedFormData[section as keyof FormData]) {
        (updatedFormData[section as keyof FormData] as any)[property] = value;
      }
    }
    
    // Update the form data
    setFormData(updatedFormData);
  };


  const handleFileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  }

  const handleDrop = (e:React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  }

  const handleDragOver = (e:React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }
  
  // Handle click on upload area
  const handleUploadClick = () => {
    console.log("showEditButton", showEditButton)
    if (!showEditButton) {
      fileInputRef.current?.click();
    }
  }
  // Handle reload file button click
  const handleReloadFile = (e:React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  }

  const formatPhoneNumber = (value: string): { formattedValue: string, isValid: boolean } => {
    // Remove non-numeric characters
    const cleanedValue = value.replace(/\D/g, "");
    let formattedValue = cleanedValue;
    
    // Format the phone number
    if (cleanedValue.length > 3) {
      formattedValue = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}`;
    }
    if (cleanedValue.length > 6) {
      formattedValue += `-${cleanedValue.slice(6, 10)}`;
    }
    
    // Validate: US phone numbers should be 10 digits
    const isValid = cleanedValue.length === 10;
    
    return { formattedValue, isValid };
  };
  
  

  return (
    <Box sx={{ maxWidth: '850px', mt:2 }}>
      <Box>
        <StyledHeader>
          Recommendations
        </StyledHeader>

        {/* Recommender Section */}
        <Box sx={{ 
          display: "flex", 
          flexWrap: "wrap",
          width : "100%"
        }}>
          {/* Recommender #1 */}
          <StyledBox>
          <Typography variant="subtitle1" fontWeight="medium" gutterBottom style={webStyles.subTitle}>
              Recommender #1
            </Typography>
            
            <Typography variant="body2" gutterBottom style={webStyles.fieldTitle}>
              Full Name
            </Typography>
            <Box sx={{ width: "100%" }}>
              <FormTextField
                data-test-id="recomender1Name"
                fieldPath="recommender1.fullName"
                placeholder="Anna Hirot"
                value={formData?.recommender1?.fullName}
                onChange={handleInputChange}
                disabled={showEditButton}
              />
            </Box>
            
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }} style={webStyles.fieldTitle}>
              School Email
            </Typography>
              <FormTextField
               data-test-id="recomender1Email"
                fieldPath="recommender1.email"
                placeholder="email@gmail.com"
                value={formData?.recommender1?.email}
                onChange={handleInputChange}
                error={inputErrors['recommender1.email']}
                helperText={inputErrors['recommender1.email'] ? "Please enter a valid email" : ""}
                disabled={showEditButton}
              />
          </StyledBox>

          {/* Recommender #2 */}
          <StyledBox sx={{ 
            flex: "1 1 360px", 
            minWidth: "300px"
          }}>
            <Typography variant="subtitle1" fontWeight="medium" gutterBottom style={webStyles.subTitle}>
              Recommender #2
            </Typography>
            
            <Typography variant="body2" gutterBottom style={webStyles.fieldTitle}>
              Full Name
            </Typography>
            <Box sx={{ width: "100%" }}>
              <FormTextField
                fieldPath="recommender2.fullName"
                placeholder="Anna Hirot"
                value={formData?.recommender2?.fullName}
                onChange={handleInputChange}
                disabled={showEditButton}
              />
            </Box>
            
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }} style={webStyles.fieldTitle}>
              School Email
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="recommender2.email"
              placeholder="email@gmail.com"
              value={formData?.recommender2?.email}
              onChange={handleInputChange}
              error={inputErrors['recommender2.email']}
              helperText={inputErrors['recommender2.email'] ? "Please enter a valid email" : ""}
              disabled={showEditButton}
            />
            </Box>
          </StyledBox>
        </Box>

        {/* Parent or Guardian Section */}
        <StyledHeader sx={{ mt: 4 }}>
          Parent or Guardian Information
        </StyledHeader>
        
        <Box sx={{ 
          display: "flex", 
          flexWrap: "wrap", 
          gap: "24px"
        }}>
          <StyledBox sx={{ 
            flex: "1 1 360px", 
            minWidth: "300px"
          }}>
            <Typography variant="body2" gutterBottom style={webStyles.fieldTitle}>
              Full Name
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="guardian.fullName"
              placeholder="Anna Hirot"
              value={formData?.guardian?.fullName}
              onChange={handleInputChange}
              disabled={showEditButton}
            />
            </Box>
            
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }} style={webStyles.fieldTitle}>
              School Email
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="guardian.email"
              placeholder="email@gmail.com"
              value={formData?.guardian?.email}
              onChange={handleInputChange}
              error={inputErrors['guardian.email']}
              helperText={inputErrors['guardian.email'] ? "Please enter a valid email" : ""}
              disabled={showEditButton}
            />
            </Box>
          </StyledBox>
          
          <StyledBox sx={{ 
            flex: "1 1 360px", 
            minWidth: "300px"
          }}>
            <Typography variant="body2" gutterBottom style={webStyles.fieldTitle}>
              Phone Number
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
            data-test-id="guardianPhone"
              fieldPath="guardian.phone"
              placeholder="(201) 555-5555"
              value={formData?.guardian?.phone}
              onChange={handleInputChange}
              error={inputErrors['guardian.phone']}
              helperText={inputErrors['guardian.phone'] ? "Please enter a valid 10-digit phone number" : ""}
              disabled={showEditButton}
            />
            </Box>
          </StyledBox>
        </Box>

        {/* School Academic Advisor */}
        <StyledHeader sx={{ mt: 4 }}>
          School Academic Advisor
        </StyledHeader>
        
        <Box sx={{ 
          display: "flex", 
          flexWrap: "wrap", 
          gap: "24px"
        }}>
          <StyledBox sx={{ 
            flex: "1 1 360px", 
            minWidth: "300px"
          }}>
            <Typography variant="body2" gutterBottom style={webStyles.fieldTitle}>
              Full Name
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="advisor.fullName"
              placeholder="Ben Ligio"
              value={formData?.advisor?.fullName}
              onChange={handleInputChange}
              disabled={showEditButton}
            />
            </Box>
            
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }} style={webStyles.fieldTitle}>
              School Email
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="advisor.email"
              placeholder="email@gmail.com"
              value={formData?.advisor?.email}
              onChange={handleInputChange}
              error={inputErrors['advisor.email']}
              helperText={inputErrors['advisor.email'] ? "Please enter a valid email" : ""}
              disabled={showEditButton}
            />
            </Box>
          </StyledBox>
          
          <StyledBox sx={{ 
            flex: "1 1 360px", 
            minWidth: "300px"
          }}>
            <Typography variant="body2" gutterBottom style={webStyles.fieldTitle}>
              Title
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="advisor.title"
              placeholder="1702 Lake Roberts Landing Dr"
              value={formData?.advisor?.title}
              onChange={handleInputChange}
              disabled={showEditButton}
            />
            </Box>
            
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }} style={webStyles.fieldTitle}>
              School Phone
            </Typography>
            <Box sx={{ width: "100%" }}>
            <FormTextField
              fieldPath="advisor.phone"
              placeholder="(201) 555-5555"
              value={formData?.advisor?.phone}
              onChange={handleInputChange}
              error={inputErrors['advisor.phone']}
              helperText={inputErrors['advisor.phone'] ? "Please enter a valid 10-digit phone number" : ""}
              disabled={showEditButton}
            />
            </Box>
          </StyledBox>
        </Box>

        {/* School ID Check */}
        <StyledHeader sx={{ mt: 4 }}>
          School ID Check
        </StyledHeader>
        {hasFile &&
        <Box sx={{ mt: 2, backgroundColor: '#f9f9f9', p: 1, borderRadius: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={fileIcon.default} style={{width : "24px", height : "18px"}} />

            <Typography variant="body2"> . 
              {formData?.schoolId?.fileName || "School_ID.jpeg"} .<span
              data-test-id="previewBtn"
              style={{
                color : "#0F91D2",
                cursor : "pointer"
              }} onClick={togglePreview}>
                Preview
                </span>
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ ml: 'auto' }}>
              {formData?.schoolId?.fileSize || "5.3MB"}
            </Typography>
          </Box>
        </Box>
        }

        {/* File Preview Section */}
{hasFile && showPreview && (
  <Box 
    sx={{ 
      mt: 2, 
      border: '1px solid #e0e0e0', 
      borderRadius: '10px',
      overflow: 'hidden',
      position: 'relative',
      maxWidth: '530px',
      height: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f5f5f5'
    }}
  >
    {/* Close button */}
    <IconButton 
      size="small" 
      data-test-id="closePreviewBtn"
      onClick={closePreview}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>

    {/* Preview content */}
    {formData?.schoolId?.fileData && (
      formData.schoolId.fileType?.startsWith('image/') ? (
        // Image preview
        <img 
          src={formData.schoolId.fileData} 
          alt="Preview"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}
        />
      ) : formData.schoolId.fileType === 'application/pdf' ? (
        // PDF preview message
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" color="text.secondary">
            PDF Preview
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formData.schoolId.fileName}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            PDF preview is not available. Please download to view.
          </Typography>
        </Box>
      ) : (
        // Fallback for other file types
        <Typography variant="body1" color="text.secondary">
          Preview not available for this file type
        </Typography>
      )
    )}
  </Box>
)}

        {/* Upload Section */}
        <StyledUploadSection 
        data-test-id="uploadSection"
          ref={dropAreaRef}
          onClick={handleUploadClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          sx={{ 
            mt: 3, 
            border: '1px dashed #bdbdbd', 
            borderRadius: "10px", 
            maxWidth : "530px",
            textAlign: 'center',
            backgroundColor: '#fafafa',
            display : "flex",
            alignItems : "center",
            flexWrap : "wrap",
            padding : "16px 24px 16px 32px",
            opacity: showEditButton ? 0.7 : 1,
            cursor: showEditButton ? 'default' : 'pointer',
          }}
        >
          <img src={upload.default} style={{width : "48px", height : "48px", marginRight : "24px"}} />
          <StyledUploadArea >
          <Box>
            <Typography variant="body2" gutterBottom style={{fontSize : "13px"}}>
              Select a file or drag and drop here
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
              JPEG, PNG or PDF (Max size 10MB)
            </Typography>
          </Box>
          {(formData.schoolId && formData.schoolId.fileName && !showEditButton) ? (
          <StyledButton 
            variant="outlined"
            data-test-id="reloadBtn" 
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleReloadFile(e);
            }}
          >
            RELOAD FILE
          </StyledButton>
          ):null}
          </StyledUploadArea>
        </StyledUploadSection>
        <input
          data-test-id="uploadFile"
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.pdf"
          style={{ display: "none" }}
        />
      </Box>
    </Box>
  );
};

const webStyles = {
  title : {
    fontFamily: "Manrope, sans-serif",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "28.8px",
    letterSpacing: "0%",
    marginBottom : "16px"
  },
  subTitle : {
    fontFamily: "Manrope, sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19.2px",
    letterSpacing: "0%",
  },
  fieldTitle : {
    fontFamily: "Manrope, sans-serif",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19.12px",
    letterSpacing: "0%",
    marginBottom : "18px",
    color : "#25324B"
  },
  textFieldStyle : {
    width: "360px",
    height: "56px",
    borderRadius: "8px",
    borderWidth: "1px",
  }
}

export default RecommendationsForm;